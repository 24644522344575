import React, { useEffect, useRef, useState, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import logo from "../assets/logo.svg";
import search from "../assets/svg/search.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchClient } from "../axios-config";
import language from "../Json/locale.json";
import Search from "./Search";
import useStorage from "../hooks/useStorage";

function Header() {
  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
  } = useAuth0();
  const location = useLocation();
  const ref = useRef(null);
  const headerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menus, setMenus] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [lang, setLang] = useState("E");
  const cancelButtonRef = useRef(null);
  const locale = useStorage();
  const navigate = useNavigate();

  useEffect(() => {
    setLang(localStorage.getItem("lang") === "ar" ? "A" : "E");
    document
      .getElementsByTagName("html")[0]
      .setAttribute(
        "dir",
        localStorage.getItem("lang") === "ar" ? "rtl" : "ltr"
      );
    const getData = async () => {
      const resData = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        `menu?populate=*`,
        "",
        true
      );
      if (resData) setMenus(resData.data.attributes.Menu);
    };
    getData();
  }, []);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        // const token =
        await getAccessTokenSilently({
          audience: `https://${process.env.REACT_APP_AUTH0_AUDIENCE}/api/v2/`,
          scope: "read:current_user",
          // ignoreCache: true,
        });
        // localStorage.setItem("token", token);
      } catch (e) {
        console.log(e.message);
      }
    };
    getAccessToken();
    const interval = setInterval(
      () => isAuthenticated && getAccessToken(),
      10000
    );
    return () => {
      clearInterval(interval);
    };
  }, [getAccessTokenSilently, user?.sub]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !headerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    if (window.innerWidth < 768 && isOpen) {
      document
        .getElementsByClassName("pageIntroBannerConetnt")[0]
        ?.classList?.add("hidden");
    } else {
      document
        .getElementsByClassName("pageIntroBannerConetnt")[0]
        ?.classList?.remove("hidden");
    }
  }, [isOpen]);

  const changeLayoutDir = (dir) => {
    setLoading(true);
    document.getElementsByTagName("html")[0].setAttribute("dir", dir);
    localStorage.setItem("lang", dir === "rtl" ? "ar" : "en");
    window.dispatchEvent(new Event("storage"));
    if (dir === "rtl") {
      setLang("A");
    } else {
      setLang("E");
    }
    setTimeout(() => window.location.reload(), 2000);
  };

  return (
    <>
      {location.pathname !== ("/loginRedirect" && "/search") && (
        <>
          <div
            ref={headerRef}
            className="absolute w-full z-20 bg-header-gradient"
          >
            <div className="relative w-full flex items-center p-[24px] md:p-[48px]">
              <div className="w-[245px] h-[40px] bg-[#ffffff4d] rounded-[30px] px-[15px] py-[8px] hidden md:flex items-center">
                <input
                  className="text-[16px] placeholder-white text-white bg-transparent border-none outline-none w-full"
                  placeholder={language.search_placeholder[locale]}
                  onClick={() => setOpenSearchResults(true)}
                />
              </div>
              <Search
                openSearchResults={openSearchResults}
                setOpenSearchResults={setOpenSearchResults}
              />
              {isOpen ? (
                <div className="flex md:hidden items-center">
                  <p
                    className={`${
                      lang === "E" && "opacity-50"
                    } text-white text-[16px] cursor-pointer`}
                    onClick={() => {
                      changeLayoutDir("rtl");
                    }}
                  >
                    A
                  </p>
                  <span className="text-white opacity-50 mx-[10px] text-[24px]">
                    |
                  </span>
                  <p
                    className={`${
                      lang === "A" && "opacity-50"
                    } text-white text-[16px] cursor-pointer`}
                    onClick={() => {
                      changeLayoutDir("ltr");
                    }}
                  >
                    E
                  </p>
                </div>
              ) : (
                <div
                  className={`w-[40px] h-[40px] bg-[#ffffff4d] rounded-[30px] p-[12px] flex md:hidden items-center`}
                  onClick={() => setOpenSearchResults(true)}
                >
                  <img src={search} alt="" />
                </div>
              )}

              <Link
                className={`absolute left-[50%] translate-x-[-50%] ${
                  isOpen && "hidden md:!block"
                }`}
                to="/"
              >
                <img src={logo} alt="" />
              </Link>
              <div className="flex ms-auto gap-[40px]">
                <div className="hidden md:flex items-center">
                  <p
                    className={`${
                      lang === "E" && "opacity-50"
                    } text-white text-[16px] cursor-pointer`}
                    onClick={() => {
                      changeLayoutDir("rtl");
                    }}
                  >
                    A
                  </p>
                  <span className="text-white opacity-50 mx-[10px] text-[24px]">
                    |
                  </span>
                  <p
                    className={`${
                      lang === "A" && "opacity-50"
                    } text-white text-[16px] cursor-pointer`}
                    onClick={() => {
                      changeLayoutDir("ltr");
                    }}
                  >
                    E
                  </p>
                </div>

                {/* <div
            className=""
            onClick={() => {
              setIsOpen((prevState) => !prevState);
            }}
          > */}
                {isOpen ? (
                  <button
                    type="button"
                    class="w-[44px] h-[44px] bg-white/30 rounded-full p-2 inline-flex items-center justify-center text-gray-400"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="#fff"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    className="gradient-border p-[4px] relative rounded-full cursor-pointer before:rounded-full"
                  >
                    <div className="menu-gradient w-[36px] h-[36px] rounded-full"></div>
                    {/* radial-gradient(100% 100%, transparent 20px, black 0) */}
                  </button>
                )}
              </div>
            </div>
          </div>
          <Transition
            show={isOpen}
            ref={ref}
            className="z-10 fixed top-0 end-0 w-full md:w-auto"
            // enter="transition ease-out duration-100 transform"
            // enterFrom="-translate-y-[256px] md:-translate-y-0 md:-translate-x-[-256px]"
            // enterTo="-translate-y-0 md:-translate-x-full"
            // leave="transition ease-in duration-75 transform"
            // leaveFrom="-translate-y-0 md:-translate-x-full"
            // leaveTo="-translate-y-[256px] md:-translate-y-0 md:-translate-x-[-256px]"
            enter="transition ease-out duration-100 transform"
            enterFrom={`-translate-y-[256px] md:-translate-y-0 ${
              lang === "E"
                ? "md:-translate-x-[-256px]"
                : "md:-translate-x-[256px]"
            }`}
            enterTo="-translate-y-0 md:-translate-x-full"
            leave="transition ease-in duration-75 transform"
            leaveFrom="-translate-y-0 md:-translate-x-full"
            leaveTo={`-translate-y-[256px] md:-translate-y-0 ${
              lang === "E"
                ? "md:-translate-x-[-256px]"
                : "md:-translate-x-[256px]"
            }`}
          >
            {(ref) => (
              <div
                ref={ref}
                className={`fixed ${
                  location.pathname === "/contact" ||
                  location.pathname === "/profile" ||
                  location.pathname === "/pages/terms-conditions"
                    ? "bg-[#000000eb] sm:bg-[#000000eb] md:bg-[#0000005e] "
                    : "bg-[#0000005e]"
                } w-full md:w-[265px] h-screen end-0 px-[24px] md:px-[48px] pt-[130px] flex flex-col`}
              >
                {!isAuthenticated ? (
                  <Link
                    onClick={loginWithRedirect}
                    to="#"
                    className="text-[20px] text-white my-[6px]"
                  >
                    {language.sign_in[locale]}
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    to="/profile"
                    className="text-[20px] text-white my-[6px]"
                  >
                    {language.my_account[locale]}
                  </Link>
                )}
                {menus.map((menu) => (
                  <Link
                    key={menu.id}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    to={menu.link}
                    className="text-[20px] text-white my-[6px] whitespace-nowrap"
                  >
                    {menu.label}
                  </Link>
                ))}
                <Link
                  onClick={() => {
                    setIsOpen(false);
                  }}
                  to="/happenings/tag/pos"
                  className="text-[20px] text-white my-[6px]"
                >
                  Pos Items
                </Link>
                {isAuthenticated && (
                  <Link
                    onClick={() => {
                      localStorage.removeItem("user_id");
                      window.dispatchEvent(new Event("storage"));
                      setIsOpen(false);
                    }}
                    to="/logout"
                    className="text-[20px] text-white my-[6px]"
                  >
                    {language.logout[locale]}
                  </Link>
                )}
              </div>
            )}
          </Transition>
          <Transition.Root show={loading} as={Fragment}>
            <Dialog
              as="div"
              style={{ zIndex: 9999 }}
              className="relative"
              initialFocus={cancelButtonRef}
              onClose={() => {}}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>
              <div className="fixed z-10 inset-0 overflow-y-auto">
                <div className="flex items-end justify-center pt-4 px-4 pb-40 text-center sm:block sm:p-0">
                  <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                  >
                    &#8203;
                  </span>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative inline-block rounded-[30px] text-left overflow-hidden transform transition-all p-8 mt-[14rem]">
                      <div
                        role="status"
                        className="flex justify-center w-full items-end"
                      >
                        <svg
                          aria-hidden="true"
                          class="me-2 w-8 h-8 text-[#616161] animate-spin fill-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      )}
    </>
  );
}

export default Header;
