import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchClient } from "../axios-config";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import { useAuth0 } from "@auth0/auth0-react";

export default function ContactForm({ selectedType = "", openContactForm }) {
  const DEFAULT_DATA = {
    first_name: "",
    last_name: "",
    email: "",
    type: selectedType,
    message: "",
    document: [],
    terms: true,
  };
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(DEFAULT_DATA);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const locale = useStorage();

  const handeChangeData = (input) => {
    setData((prev) => {
      return {
        ...prev,
        [input.name]:
          input.name === "document"
            ? [...input.files]
            : input.name === "terms"
            ? input.checked
            : input.value,
      };
    });
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "createZenDeskTicket");
    formData.append("first_name", data.first_name);
    formData.append("last_name", data.last_name);
    formData.append("email", data.email);
    formData.append("attachments[]", data.document);
    formData.append("terms", data.terms);
    formData.append("subject", data.type);
    formData.append("comment", data.message);
    const resData = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (resData) {
      setData(DEFAULT_DATA);
      setShowMessage(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    setShowMessage(false);
  }, [openContactForm]);

  return (
    <>
      {showMessage ? (
        <div className="text-center text-white mt-[2rem]">
          <h2 className="text-[24px]">{language.thank_you[locale]}</h2>
          <h4 className="text-[24px]">{language.request_recieved[locale]}</h4>
          <h4 className="mt-[1rem]">{language.staff_contact[locale]}</h4>
        </div>
      ) : (
        <form onSubmit={handleSendMessage}>
          <h4 className="text-[20px] text-white mb-[20px] mt-[2rem]">
            {language.send_message[locale]}
          </h4>
          <div className="flex gap-[12px]">
            <input
              className="px-[24px] py-[16px] rounded-[50px] w-full md:w-[350px] text-white me-[12px] bg-[#11111180]"
              type="text"
              placeholder={language.name[locale]}
              required
              name="first_name"
              value={data.first_name}
              onChange={(e) => handeChangeData(e.target)}
              id=""
            />
            <input
              className="px-[24px] py-[16px] rounded-[50px] w-full md:w-[350px] mt-[12px] md:mt-0 text-white bg-[#11111180]"
              type="text"
              placeholder={language.surname[locale]}
              required
              name="last_name"
              value={data.last_name}
              onChange={(e) => handeChangeData(e.target)}
              id=""
            />
          </div>
          <div className="mt-[12px] flex gap-[12px] mb-[12px]">
            <input
              className="px-[24px] py-[16px] rounded-[50px] w-full md:w-[350px] text-white me-[12px] bg-[#11111180]"
              type="email"
              placeholder={language.email[locale]}
              required
              name="email"
              value={data.email}
              onChange={(e) => handeChangeData(e.target)}
              id=""
            />
            <select
              className="px-[24px] py-[16px] rounded-[50px] w-full md:w-[350px] text-[#FFFFFF80] bg-[#11111180] mt-[12px] md:mt-0"
              type="text"
              value={data.type}
              required
              onChange={(e) => handeChangeData(e.target)}
              name="type"
              id=""
            >
              {language.types[locale].map((type, idx) => (
                <option value={!idx ? "" : type}>{type}</option>
              ))}
            </select>
          </div>
          <textarea
            placeholder={language.message[locale]}
            className="px-[24px] py-[16px] h-[170px] rounded-[20px] w-full md:w-[700px] text-white bg-[#11111180] mb-[12px]"
            type="text"
            name="message"
            required
            value={data.message}
            onChange={(e) => handeChangeData(e.target)}
          />
          <div className="flex md:justify-between flex-col md:flex-row md:items-center w-full md:w-[700px] mb-[42px]">
            <input
              type="file"
              name="document"
              id="fileupload"
              hidden
              multiple
              onChange={(e) => handeChangeData(e.target)}
            />
            <label htmlFor="fileupload">
              <div className="rounded-[50px] w-[130px] bg-[#11111180] flex justify-center items-center border-[1.5px] border-[#FFFFFF33] h-[36px]">
                <p className="text-white text-[16px]">
                  {language.attach_file[locale]}
                </p>
              </div>
            </label>
            <div className="mt-[24px] md:mt-0 flex items-center gap-[12px]">
              <input
                type="checkbox"
                name="terms"
                checked={data.terms}
                required
                onChange={(e) => handeChangeData(e.target)}
                id="agree"
                className="w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] bg-transparent"
              />
              <label
                htmlFor="agree"
                className="text-white ms-[10px] text-[16px]"
              >
                <span className="text-[#FFFFFF80]">
                  {language.agree_terms_conditions[locale]}
                </span>{" "}
                <Link to="/terms">
                  {language.terms_conditions[locale].toLowerCase()}
                </Link>
              </label>
            </div>
          </div>
          {data.document.map((doc) => (
            <p className="text-white">{doc.name}</p>
          ))}
          <div className="flex w-full md:w-[700px] justify-end">
            {loading ? (
              <div
                role="status"
                className="flex justify-end w-full h-full items-center"
              >
                <svg
                  aria-hidden="true"
                  class="me-2 w-8 h-8 text-[#616161] animate-spin fill-white"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span class="sr-only">Loading...</span>
              </div>
            ) : (
              <button
                type="submit"
                className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                disabled={loading}
              >
                {language.send_message[locale]}
              </button>
            )}
          </div>
        </form>
      )}
    </>
  );
}
