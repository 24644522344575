import React, { useEffect, useRef, useState } from "react";
import Banner from "../components/Banner";
import Events from "../components/Events";
import Reserve from "../components/Reserve";
import lab from "../assets/svg/lab.svg";
import happenings from "../assets/images/happenings.webp";
import animate from "../gsap/animate";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

const reserveData = [
  {
    title: "Al Jazri Lab",
    desc: "Our digital fabrication lab has a variety of unique machinery including 3D printers and laser cutters.",
    image: lab,
  },
];

const Happenings = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const ref = useRef([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const locale = useStorage();

  useEffect(() => {
    animate(ref);
    const getPackages = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "packagesByTags");
      formData.append("tag", params.tag_slug);
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "",
        formData,
        false
      );
      if (data) setPackages(data.data);
      setLoading(false);
    };
    getPackages();
  }, []);

  return (
    <>
      <Banner
        url={happenings}
        title={language.happenings_title[locale]}
        desc={language.happenings_desc[locale]}
      />
      <Events
        title={language.events[locale]}
        packages={packages}
        ref={(el) => (ref.current[0] = el)}
        loading={loading}
      />
      {/* <Reserve data={reserveData} ref={(el) => (ref.current[1] = el)} /> */}
    </>
  );
};

export default Happenings;
