import React, { useState } from "react";
import moment from "moment";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const Summary = ({
  addChild,
  addPerson,
  adult_price,
  child_price,
  selectedDate,
  selectedTimeSlot,
  timeSlots,
  packageName,
  shipping_req,
  total,
  enable_date_picker,
  enable_time_picker,
  shipping,
  setShipping,
  selectedSummerDate,
  selectedSummerGroup,
  summerCamp,
  count,
}) => {
  const locale = useStorage();

  const totalValue = (
    summerCamp
      ? ((selectedSummerGroup
          ? Number(selectedSummerGroup.price)
          : selectedSummerDate.reduce((sum, value) => {
              return sum + Number(value.price);
            }, 0)) +
          total) *
        count
      : total
  ).toFixed(2);

  return (
    <div className="flex flex-col justify-between mt-[84px] md:mt-0">
      <div className="bg-transparent md:min-w-[400px] w-full flex justify-between p-[10px] md:p-[20px] rounded-[13px] font-SansSerif">
        <div>
          <h3 className="text-white text-[17px] font-semibold">
            {packageName}
          </h3>
          {enable_time_picker && (
            <h3 className="text-white text-[17px]">
              {timeSlots[selectedTimeSlot].time_slot}
            </h3>
          )}
          <br />
          {enable_date_picker && (
            <h3 className="text-white text-[17px]">
              {moment(selectedDate).format("LL")}
            </h3>
          )}
          {child_price && (
            <h3 className="text-white text-[17px]">
              {language.children[locale]} {addChild}
            </h3>
          )}
          {adult_price && (
            <h3 className="text-white text-[17px]">
              {language.adult[locale]} {addPerson}
            </h3>
          )}
          {summerCamp && (
            <h3 className="text-white text-[17px]">
              {selectedSummerGroup
                ? selectedSummerGroup.group_name
                : selectedSummerDate.map((date) => date.date).join(", ")}
            </h3>
          )}
          <p className="text-white text-[17px] font-semibold">
            {totalValue} AED
          </p>
          {shipping_req && (
            <div className="my-[12px] flex items-center gap-[12px] w-full">
              <input
                type="checkbox"
                name="student_check"
                id="student_check"
                checked={shipping}
                onChange={(e) => setShipping(e.target.checked)}
                className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ml-[20px] md:ml-0 ${
                  shipping ? "" : "opacity-0"
                }`}
              />
              <div
                className={`w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] ${
                  locale === "en" ? "ml-[-28px]" : "mr-[-28px]"
                } md-ml-[-8px] ${shipping ? "hidden" : ""}`}
              />
              <label
                htmlFor="student_check"
                className="text-white ms-[10px] text-[16px]"
              >
                {language.shipping[locale]} (+30 AED)
              </label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Summary;
