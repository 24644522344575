import React from "react";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import logo1 from "../assets/images/001.png";
import logo2 from "../assets/logo.svg";
import logo3 from "../assets/images/002.png";
import logo4 from "../assets/images/003.png";
import logo5 from "../assets/images/004.png";
import logo6 from "../assets/images/005.png";

export default function Duathlon() {
  const locale = useStorage();
  const images = [logo1, logo2, logo3, logo4, logo5, logo6];
  return (
    <>
      <div className="w-full h-[60vh] md: bg-cover bg-center flex justify-center items-center mt-[10rem]">
        <div className="flex flex-col justify-center items-center h-full w-[90%] md:w-[600px] pageIntroBannerConetnt">
          <h2 className="text-white text-[32px] text-center">
            {language.sharjah_charity[locale]}
          </h2>
          <h6 className="text-white text-[16px] mt-[8px] mb-[24px] text-center">
            {language.duathlon_desc1[locale]}
          </h6>
          <h6 className="text-white text-[16px] mt-[8px] mb-[24px] text-center">
            {language.duathlon_desc2[locale]}
          </h6>
          <h6 className="text-white text-[16px] mt-[8px] mb-[24px] text-center">
            {language.duathlon_desc3[locale]}
          </h6>
          <button
            className="text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[1rem]"
            onClick={() =>
              window.open(
                "https://www.hopasports.com/en/event/sharjah-charity-duathlon-2023"
              )
            }
          >
            {language.register_now[locale]}
          </button>
        </div>
      </div>
      <div className="flex gap-[3rem] justify-center items-center mt-[3rem]">
        {images.map((image) => (
          <img src={image} className="w-[150px] h-[150px] object-contain" />
        ))}
      </div>
    </>
  );
}
