import React from "react";
import ImageCarousel from "./ImageCarousel";
import close from "../../assets/svg/close.svg";
import moment from "moment";
import { useState } from "react";
import Loader from "../Modal/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const EventDetails = ({ nextStep, closeModal, packageDetails }) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [selectedTab, setSelectedTab] = useState(0);
  const [readMore, setReadMore] = useState(false);
  const locale = useStorage();

  const LocationContent = (props) => {
    const { lan, lat } = props;
    let url =
      "https://maps.google.com/maps?q=" +
      lat +
      "," +
      lan +
      "&hl=es;z=14&output=embed";
    return (
      <div className="text-content">
        <iframe src={url}></iframe>
      </div>
    );
  };

  const truncateHtml = (htmlString, maxLength) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const body = doc.body;
    let truncatedLength = 0;
    const stack = [body];
    while (stack.length > 0 && truncatedLength < maxLength) {
      const element = stack.pop();
      if (element.nodeType === Node.TEXT_NODE) {
        const remainingLength = maxLength - truncatedLength;
        const text = element.textContent.slice(0, remainingLength);
        truncatedLength += text.length;
        element.textContent = text;
      } else {
        for (let i = element.childNodes.length - 1; i >= 0; i--) {
          const child = element.childNodes[i];
          if (child.nodeType === Node.TEXT_NODE) {
            const remainingLength = maxLength - truncatedLength;
            const text = child.textContent.slice(0, remainingLength);
            truncatedLength += text.length;
            child.textContent = text;
          } else {
            stack.push(child);
          }
        }
      }
    }
    return body.innerHTML;
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row gap-[48px] overflow-hidden">
        <div className="fixed top-0 left-0 right-[24px] z-10 bg-modalBg md:max-h-[90vh] pt-[20px] pb-[10px] block md:hidden w-auto flex justify-end">
          <button
            className="block md:hidden relative opacity-7 h-[36px] min-w-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
            onClick={closeModal}
          >
            <img
              src={close}
              alt="close"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </button>
        </div>
        <div className="relative md:w-7/12 mt-[84px] md:mt-0">
          <ImageCarousel image={packageDetails?.img} />
          {/* <img src={events} alt="" className="w-[557px] h-[432px]" /> */}
        </div>
        <div className="md:w-5/12 pb-[6rem] md:pb-0">
          {packageDetails ? (
            <>
              <h2 className="text-[20px] md:text-[32px] text-white mb-[36px]">
                {packageDetails?.package_name}
              </h2>
              <p className="text-white text-[16px]">
                {readMore ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: packageDetails?.description,
                    }}
                  />
                ) : (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: packageDetails?.description
                        ? truncateHtml(packageDetails.description, 350)
                        : "",
                    }}
                  />
                )}
                {packageDetails?.description?.replace(/<\/?[^>]+(>|$)/g, "")
                  .length > 350 && (
                  <span
                    className="opacity-50 cursor-pointer"
                    onClick={() => setReadMore(!readMore)}
                  >
                    {readMore
                      ? language.read_less[locale]
                      : language.read_more[locale]}
                  </span>
                )}{" "}
                <br />
                <br />
                {packageDetails?.start_date && packageDetails?.end_date && (
                  <>
                    {language.date[locale]}:{" "}
                    <span className="opacity-50">
                      {moment(packageDetails?.start_date, "YYYY-MM-DD").format(
                        "ll"
                      )}{" "}
                      -{" "}
                      {moment(packageDetails?.end_date, "YYYY-MM-DD").format(
                        "ll"
                      )}
                    </span>{" "}
                  </>
                )}
                <br />
                {/* Ages: <span className="opacity-50">12+</span> <br /> */}
                {packageDetails?.location && (
                  <>
                    {language.location[locale]}:{" "}
                    {/* <span className="opacity-50 underline">View on map</span>{" "} */}
                    <span className="opacity-50">
                      {packageDetails?.location}
                    </span>
                    <br />
                  </>
                )}
                {packageDetails?.price && (
                  <>
                    {language.adult[locale]}:{" "}
                    <span className="opacity-50">
                      AED {packageDetails?.price}{" "}
                    </span>
                    <br />
                  </>
                )}
                {packageDetails?.child_price && (
                  <>
                    {language.children[locale]}:{" "}
                    <span className="opacity-50">
                      AED {packageDetails?.child_price}{" "}
                    </span>
                  </>
                )}
              </p>
              {!isAuthenticated ? (
                <button
                  className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative my-[50px]"
                  onClick={loginWithRedirect}
                >
                  {language.login[locale]}
                </button>
              ) : (
                <>
                  {!packageDetails?.tags?.includes("bookclub") && (
                    <button
                      className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative my-[50px]"
                      onClick={nextStep}
                      disabled={
                        packageDetails?.disabled === "1" ||
                        packageDetails?.enable === "0" ||
                        moment(packageDetails?.end_date).isBefore(
                          moment(),
                          "day"
                        )
                      }
                    >
                      {language.book[locale]}
                    </button>
                  )}
                </>
              )}

              {(packageDetails?.disabled === "1" ||
                packageDetails?.enable === "0" ||
                moment(packageDetails?.end_date).isBefore(moment(), "day")) && (
                <p className="text-white text-[16px]">
                  {language.bookings_disabled[locale]}
                </p>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
        <button
          className="hidden md:block relative opacity-7 h-[36px] min-w-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
          onClick={closeModal}
        >
          <img
            src={close}
            alt="close"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        </button>
      </div>
      {/* <div className="mt-[32px] md:w-6/12">
        <ul className="flex text-[20px] gap-[64px] mb-[36px]">
          <li
            className={`text-white cursor-pointer pb-[8px] ${
              selectedTab === 0 ? "border-b-[1px] border-white" : "opacity-50"
            }`}
            onClick={() => setSelectedTab(0)}
          >
            Information
          </li>
          <li
            className={`text-white cursor-pointer pb-[8px] ${
              selectedTab === 1 ? "border-b-[1px] border-white" : "opacity-50"
            }`}
            onClick={() => setSelectedTab(1)}
          >
            Location
          </li>
        </ul>
        {selectedTab === 0 ? (
          <p className="text-white text-[16px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae
            scelerisque neque. Sed ac mollis erat. Duis quis porttitor tortor.
            Nulla at augue ac lorem malesuada rutrum. Morbi tincidunt auctor
            dapibus. Suspendisse luctus erat non aliquet imperdiet. Nulla eget
            porta arcu, vitae mattis ante. In ac dui sem. Pellentesque
            porttitor, nunc eget lobortis faucibus, mi justo tincidunt ante,
            placerat feugiat erat turpis vel tortor.
          </p>
        ) : (
          <p className="text-white text-[16px]">
            <LocationContent
              lan={packageDetails?.lan}
              lat={packageDetails?.lat}
            />
          </p>
        )}
      </div> */}
    </div>
  );
};

export default EventDetails;
