import React, { useEffect, useRef, useState } from "react";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "../utils/notifyToast";

export default function TempLogin() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [userData, setUserData] = useState();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  localStorage.setItem("last_visited_url", location.pathname);

  useEffect(() => {
    const formData = new FormData();
    formData.append("action", "userDetails");
    formData.append("id", params.user_id);
    const getData = async () => {
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "internal_portal.php",
        formData,
        false
      );
      if (data) setUserData(data.data);
    };
    getData();
  }, [isAuthenticated]);

  const handleUserId = (set) => {
    if (set === "add") localStorage.setItem("user_id", params.user_id);
    else localStorage.removeItem("user_id");
    window.dispatchEvent(new Event("storage"));
    notifySuccess("Success");
    setTimeout(() => navigate("/"), 500);
  };

  return (
    <div className="flex flex-col gap-[2rem] justify-center items-center mt-[10rem] p-14">
      {isAuthenticated ? (
        <>
          {userData && (
            <>
              <table className="text-white">
                <tr>
                  <th className="border border-white p-[10px]">ID</th>
                  <th className="border border-white p-[10px]">Name</th>
                  <th className="border border-white p-[10px]">Email</th>
                  <th className="border border-white p-[10px]">
                    Membership Role
                  </th>
                </tr>
                <tr>
                  <td className="border border-white p-[10px]">
                    {userData.id}
                  </td>
                  <td className="border border-white p-[10px]">
                    {userData.name}
                  </td>
                  <td className="border border-white p-[10px]">
                    {userData.email}
                  </td>
                  <td className="border border-white p-[10px]">
                    {userData.membership_role}
                  </td>
                </tr>
              </table>
              <div className="flex gap-[1rem] justify-center items-center">
                <button
                  className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] gradient-border relative"
                  onClick={() => handleUserId("add")}
                >
                  Confirm Login
                </button>
                <button
                  className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] gradient-border relative"
                  onClick={() => handleUserId("remove")}
                >
                  Logout
                </button>
              </div>
            </>
          )}
        </>
      ) : (
        <p className="text-white text-[24px] mt-[4rem]">Please Login</p>
      )}
    </div>
  );
}
