import React from "react";
import { useState } from "react";
import defaultImage from "../../assets/sample.jpg";
import Loader from "../Modal/Loader";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const SelectPod = ({ loading, pods, selectedPod, setSelectedPod }) => {
  const locale = useStorage();
  return (
    <div className="h-full flex flex-col justify-between font-SansSerif mb-[45px] md:mb-0">
      <div className="pb-[4rem] md:pb-0">
        <h5 className="mb-[20px] text-white text-center text-[17px]">
          {language.pod[locale]}
        </h5>
        <div className="flex flex-col justify-center items-center w-full mb-[48px] gap-[12px]">
          {!loading ? (
            <>
              {pods.length > 0 ? (
                <>
                  {pods.map((pod) => (
                    <div
                      className={`${
                        selectedPod?.id === pod.id ? "bg-white" : "bg-[#111]"
                      } w-full flex justify-between p-[20px] rounded-[13px] cursor-pointer`}
                      onClick={() => setSelectedPod(pod)}
                    >
                      {selectedPod?.id === pod.id && (
                        <img
                          src={selectedPod.featured_image}
                          alt={pod.name}
                          className="max-w-[150px] w-[8.5rem] object-cover mr-[16px]"
                          onLoad={
                            <div
                              role="status"
                              class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 mr-[16px]"
                            >
                              <div class="flex justify-center items-center max-w-[150px] h-[5.6rem] bg-gray-300 rounded w-[8.5rem] dark:bg-gray-700" />
                              <span class="sr-only">Loading...</span>
                            </div>
                          }
                        />
                      )}
                      <div
                        className={`flex ${
                          selectedPod?.id === pod.id && "flex-col"
                        } md:flex-row w-full justify-between`}
                      >
                        <div className="mr-2">
                          <h3
                            className={`${
                              selectedPod?.id === pod.id
                                ? "text-[#111]"
                                : "text-white"
                            } text-[17px] font-semibold`}
                          >
                            {pod.name}
                          </h3>
                          <h3
                            className={`${
                              selectedPod?.id === pod.id
                                ? "text-[#111]"
                                : "text-white"
                            } text-[17px]`}
                          >
                            {language.capacity[locale]}: {pod.capacity}{" "}
                            {language.people[locale]}
                          </h3>
                        </div>
                        <p
                          className={`${
                            selectedPod?.id === pod.id
                              ? "text-[#111]"
                              : "text-white"
                          } text-[17px] font-semibold mt-[12px] md:mt-0 flex gap-[10px]`}
                        >
                          {pod.total_cost !== pod.sub_total && (
                            <s>{pod.sub_total} AED</s>
                          )}
                          <div>{pod.total_cost} AED</div>
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <p className="text-white text-[23px]">
                  {language.no_pods[locale]}
                </p>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default SelectPod;
