import React, { useEffect, useRef, useState } from "react";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { notifySuccess } from "../utils/notifyToast";
import { Link } from "react-router-dom";

export default function TempUserFloatWidget() {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [userData, setUserData] = useState();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  localStorage.setItem("last_visited_url", location.pathname);

  useEffect(() => {
    const getData = async () => {
      const formData = new FormData();
      formData.append("action", "userDetails");
      formData.append("id", localStorage.getItem("user_id"));
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "internal_portal.php",
        formData,
        false
      );
      window.removeEventListener("storage", getData);
      if (data) setUserData(data.data);
      else setUserData();
    };
    getData();
    window.addEventListener("storage", getData);
    return () => {
      window.removeEventListener("storage", getData);
    };
  }, [isAuthenticated]);

  return (
    <>
      {userData && (
        <Link to={`/temp-login/${localStorage.getItem("user_id")}`}>
          <div className="w-auto mt-[1rem] fixed bottom-0 left-0 p-[10px] bg-black">
            <p className="text-white">
              {userData.name} | {userData.email} | {userData.membership_role}
            </p>
          </div>
        </Link>
      )}
    </>
  );
}
