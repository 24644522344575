import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Modal/Loader";

export default function LoginRedirect() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate(localStorage.getItem("last_visited_url"));
    }, 2500);
  }, []);
  return (
    <div>
      <Loader />
    </div>
  );
}
