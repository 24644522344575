import React from "react";
import ContactForm from "../components/ContactForm";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

function Contact() {
  const locale = useStorage();
  localStorage.setItem(
    "last_visited_url",
    window.location.pathname + window.location.search
  );

  return (
    <div className="pt-[140px] w-full flex justify-center">
      <div className="w-10/12 md:w-8/12 relative">
        <h4 className="text-[32px] text-white mt-[48px] mb-[10px]">
          {language.contact_title[locale]}
        </h4>
        <p className="text-[16px] text-white font-normal mb-[60px]">
          {language.contact_desc[locale]}:
          <ContactForm />
        </p>
        <p className="text-[16px] text-white font-normal mb-[60px]">
          <br />
          <br />
          <span className="text-white font-bold">
            {language.contact_us[locale]}
          </span>{" "}
          <br /> {language.phone_number[locale]} <br />
          <br />{" "}
          <span className="text-white font-bold">
            {language.working_hours[locale]}:
          </span>
          <br />
          {language.weekdays[locale]} <br />
          <span className="text-[#FFFFFF80]">
            {language.weekdays_time[locale]}
          </span>{" "}
          <br /> <br />
          {language.weekends[locale]} <br />
          <span className="text-[#FFFFFF80]">
            {language.weekends_time[locale]}
          </span>
        </p>
      </div>
    </div>
  );
}

export default Contact;
