import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.svg";
import facebook from "../assets/svg/facebook.svg";
import instagram from "../assets/svg/instagram.svg";
import twitter from "../assets/svg/twitter.svg";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import TempUserFloatWidget from "./TempUserFloatWidget";

function Footer() {
  const location = useLocation();
  const locale = useStorage();

  return (
    <>
      {location.pathname !== "/loginRedirect" && (
        <div className="w-full mt-[84px] p-[48px] flex flex-col items-center">
          <div className="relative w-full flex flex-col md:flex-row items-center max-w-[1800px]">
            <div className="md:absolute md:left-[50%] md:translate-x-[-50%]">
              <img src={logo} alt="" />
            </div>
            <div className="mt-[48px] flex gap-[20px] mb-[23px] md:mt-0 md:mb-0">
              {/* <Link to="/news" className="text-white text-[12px] font-medium">
                News
              </Link> */}
              <Link
                to="/pages/terms-conditions"
                className="text-white text-[12px] font-medium"
              >
                {language.terms_conditions[locale]}
              </Link>

              {/* <Link to="#" className="text-white text-[12px] font-medium">
                Media
              </Link> */}
            </div>

            <div className="flex md:ms-auto gap-[20px]">
              <img
                className="cursor-pointer"
                src={facebook}
                alt=""
                onClick={() =>
                  window.open("https://www.facebook.com/SharjahHOW", "_blank")
                }
              />
              <img
                className="cursor-pointer"
                src={instagram}
                alt=""
                onClick={() =>
                  window.open("https://www.instagram.com/SharjahHOW", "_blank")
                }
              />
              <img
                className="cursor-pointer"
                src={twitter}
                alt=""
                onClick={() =>
                  window.open("https://twitter.com/SharjahHOW", "_blank")
                }
              />
            </div>
          </div>
          <TempUserFloatWidget />

          <div className="w-full text-center mt-[55px]">
            <p className="text-[12px] text-[#8D8D8D]">
              {language.copy_rights[locale]}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
