import React, { useState } from "react";
import moment from "moment";
import Loader from "./Loader";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const Summary = ({
  selectedTimeBlock,
  selectedPod,
  selectedDate,
  count,
  notes,
  setNotes,
  radioSelect,
  setRadioSelect,
}) => {
  const locale = useStorage();
  return (
    <div className="flex flex-col justify-between mt-[84px] md:mt-0">
      <div className="bg-transparent md:min-w-[400px] w-full flex justify-between p-[10px] md:p-[20px] rounded-[13px] font-SansSerif">
        <div>
          <h3 className="text-white text-[17px] font-semibold">
            {selectedPod?.name}
          </h3>
          <h3 className="text-white text-[17px]">
            {language.capacity[locale]}: {selectedPod?.capacity}{" "}
            {language.people[locale]}
          </h3>
          <br />
          <h3 className="text-white text-[17px]">
            {moment(selectedDate).format("LL")}
          </h3>
          <h3 className="text-white text-[17px]">
            {language.from[locale]} {selectedTimeBlock.startTime}{" "}
            {language.to[locale]} {selectedTimeBlock.endTime}
          </h3>
          <h3 className="text-white text-[17px]">
            {language.for[locale]} {count} {language.no_of_people[locale]}
          </h3>
        </div>
        <p className="text-white text-[17px] font-semibold flex gap-[10px] flex-col">
          {selectedPod?.total_cost !== selectedPod?.sub_total && (
            <s>{selectedPod?.sub_total} AED</s>
          )}
          {radioSelect === "internal" ? (
            <s>{selectedPod?.total_cost} AED</s>
          ) : (
            <>{selectedPod?.total_cost} AED</>
          )}
        </p>
      </div>
      <div className="h-[40px] mt-[1rem] bg-[#ffffff4d] rounded-[30px] px-[15px] py-[8px] hidden flex items-center">
        <input
          className="text-[16px] placeholder-white text-white bg-transparent border-none outline-none w-full"
          placeholder="Notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>
      <div className="flex gap-[3rem] mt-[1rem]">
        <div className="flex justify-center items-center gap-[5px]">
          <input
            type="radio"
            id="regular"
            checked={radioSelect === "regular"}
            value={radioSelect}
            onChange={(e) => setRadioSelect("regular")}
            className="w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] bg-transparent"
          />
          <label htmlFor="regular" className="text-white ms-[10px] text-[16px]">
            {language.regular[locale]}
          </label>
        </div>
        <div className="flex justify-center items-center gap-[5px]">
          <input
            type="radio"
            id="internal"
            checked={radioSelect === "internal"}
            value={radioSelect}
            onChange={(e) => {
              setNotes(notes + " " + "internal");
              setRadioSelect("internal");
            }}
            className="w-4 h-4 text-blue-600 rounded border-gray-300 border-[1px] bg-[#151515] bg-transparent"
          />
          <label
            htmlFor="internal"
            className="text-white ms-[10px] text-[16px]"
          >
            {language.internal[locale]}
          </label>
        </div>
      </div>
    </div>
  );
};

export default Summary;
