import React, { useState, Fragment, useRef } from "react";
import QRCode from "react-qr-code";
import close from "../assets/svg/close.svg";
import { Dialog, Transition } from "@headlessui/react";

export default function PaymentModal({
  openPaymentModal,
  setOpenPaymentModal,
  initiatePayment,
}) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={openPaymentModal} as={Fragment}>
      <Dialog
        as="div"
        style={{ zIndex: 9999 }}
        className="relative"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center pt-4 px-4 pb-40 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative inline-block bg-white rounded-[30px] text-left overflow-hidden shadow-xl transform transition-all p-8 mt-[14rem] bg-[#000000eb]">
                <div className="flex justify-end mb-[2rem]">
                  <button
                    className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
                    onClick={() => {
                      setOpenPaymentModal(false);
                    }}
                  >
                    <img
                      src={close}
                      alt="close"
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    />
                  </button>
                </div>
                <div className="flex justify-center items-center gap-[2rem]">
                  <button
                    type="submit"
                    className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                    onClick={() => initiatePayment("cash")}
                  >
                    Cash
                  </button>
                  <button
                    type="submit"
                    className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative"
                    onClick={() => initiatePayment("card_manual")}
                  >
                    Card
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
