import React, { useState, useEffect, useRef } from "react";
import Banner from "../components/Banner";
import BooksList from "../components/Books/Books";
import Modal from "../components/Books/Modal";
import pods from "../assets/svg/pods.svg";
import books from "../assets/svg/books.svg";
import lab from "../assets/svg/lab.svg";

import FinderTP from "../assets/svg/1FinderTP.png";
import nahlaWaNahilTP from "../assets/svg/nahlaWaNahilTP.png";
import sirsDiscovererTP from "../assets/svg/sirsDiscovererTP.png";
import refWorksTP from "../assets/svg/refWorksTP.png";

import publicLibraryVideoTP from "../assets/svg/publicLibraryVideoTP.png";
import elibraryTP from "../assets/svg/elibraryTP.png";
import literatureTP from "../assets/svg/literatureTP.png";
import ebookCentralTP from "../assets/svg/ebookCentralTP.png";
import dissertationTP from "../assets/svg/dissertationTP.png";
import centralTP from "../assets/svg/centralTP.png";

import pressreaderTP from "../assets/svg/pressreaderTP.png";
import entrepreneurshipTP from "../assets/svg/entrepreneurshipTP.png";
import cultureGramsTP from "../assets/svg/cultureGramsTP.png";
import artsPremiumCollectionTP from "../assets/svg/artsPremiumCollectionTP.png";

import resourceTP from "../assets/svg/resourceTP.png";

import { useSelector } from "react-redux";

import ReserveCard from "../components/ReserveCard";
import animate from "../gsap/animate";
import Search from "../components/Search";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

const images_links = [
  {
    image: resourceTP,
    link: "https://platform-almanhal-com.ebooks.remotexs.co/",
  },
  {
    image: ebookCentralTP,
    link: "https://ebookcentral-proquest-com.ebooks.remotexs.co/lib/how/home.action",
  },
  {
    image: elibraryTP,
    link: "https://search-proquest-com.ebooks.remotexs.co/elibrary_",
  },
  {
    image: nahlaWaNahilTP,
    link: "https://nahlawanahil-com.ebooks.remotexs.co/ar/visitor/landing",
  },
  {
    image: artsPremiumCollectionTP,
    link: "https://search-proquest-com.ebooks.remotexs.co/",
  },
  {
    image: cultureGramsTP,
    link: "https://online-culturegrams-com.ebooks.remotexs.co/",
  },
  {
    image: entrepreneurshipTP,
    link: "https://search-proquest-com.ebooks.remotexs.co/entrepreneurship",
  },
  {
    image: pressreaderTP,
    link: "https://pressreader-com.ebooks.remotexs.co/",
  },
  {
    image: dissertationTP,
    link: "https://search-proquest-com.ebooks.remotexs.co/pqdtglobal",
  },
  {
    image: literatureTP,
    link: "https://search-proquest-com.ebooks.remotexs.co/pq1lit",
  },
  {
    image: lab,
    link: "https://pqrc-proquest-com.ebooks.remotexs.co/",
  },
  {
    image: publicLibraryVideoTP,
    link: "https://video-alexanderstreet-com.ebooks.remotexs.co/channel/public-library-video-online-premium",
  },
  {
    image: sirsDiscovererTP,
    link: "https://search-proquest-com.ebooks.remotexs.co/sirsdiscoverer",
  },
  {
    image: lab,
    link: "https://shurooq.libraryreserve.com",
  },
  {
    image: centralTP,
    link: "https://search-proquest-com.ebooks.remotexs.co/?accountid=202890&selectids=10000255",
  },
];

const Library = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [bookId, setBookId] = useState("");
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const ref = useRef([]);
  const { heading, description, image } = useSelector(
    (state) => state.data.read
  );
  const locale = useStorage();
  localStorage.setItem("last_visited_url", window.location.pathname);

  const closeModal = () => {
    window.history.replaceState(null, null, window.location.pathname);
    localStorage.setItem("last_visited_url", window.location.pathname);
    setBookId("");
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    setData(
      language.library[locale].map((pods, idx) => {
        return {
          ...pods,
          ...images_links[idx],
        };
      })
    );
    animate(ref, true);
  }, [locale]);

  const handleOpenURL = (e) => {
    e.preventDefault();
    const searchValue = document.getElementById("lib-search").value;
    window.open(
      `https://houseofwisdom-summon-serialssolutions-com.ebooks.remotexs.co/#!/search?ho=t&include.ft.matches=f&l=${
        localStorage.getItem("lang") || "en"
      }&q=${searchValue}`
    );
  };

  return (
    <>
      <Banner
        url={process.env.REACT_APP_CMS + image.data.attributes.url}
        title={heading}
        desc={description}
      />
      <BooksList openModal={openModal} setBookId={setBookId} />
      <div className="mt-[2rem] w-full flex justify-center">
        <div className="w-11/12 max-w-[1800px] relative">
          <div className="flex justify-between md:justify-center mb-[36px] flex-col md:flex-row">
            <ul className="flex text-[20px] md:text-[32px] gap-[36px] w-full overflow-scroll">
              <li
                className={`text-white cursor-pointer pb-[8px] border-b-[1px] border-white`}
              >
                {language.digital_books_title[locale]}
              </li>
            </ul>
          </div>
          <p className="text-white text-[17px]">
            {language.digital_books_desc[locale]}
          </p>
          <div class="h-[50px] w-full bg-[#ffffff4d] rounded-[30px] px-[15px] py-[12px] mb-[50px] my-[1rem] md:flex items-center">
            <form onSubmit={handleOpenURL} className="w-full">
              <input
                id="lib-search"
                class="text-[16px] placeholder-white text-white bg-transparent border-none outline-none w-full"
                placeholder={language.search[locale]}
              />
            </form>
          </div>
        </div>
      </div>
      <Search
        openSearchResults={openSearchResults}
        setOpenSearchResults={setOpenSearchResults}
      />
      <div id="scroll-target" className="w-full flex justify-center">
        <div
          className="w-11/12 max-w-[1800px] grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[12px]"
          ref={(el) => (ref.current[0] = el)}
        >
          {data.map((card) => (
            <ReserveCard card={card} setIsModalOpen={setIsModalOpen} isGrid />
          ))}
        </div>
      </div>
      <Modal isOpen={isOpen} closeModal={closeModal} bookId={bookId} />
    </>
  );
};

export default Library;
