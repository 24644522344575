import React, { useState, useRef, Fragment } from "react";
import Card from "./Card";
import Modal from "./Books/Modal";
import Book from "./Books/Book";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import Pagination from "./Pagination";
import Events from "../components/Events";
import animate from "../gsap/animate";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";
import { useNavigate } from "react-router-dom";

function Search({ openSearchResults, setOpenSearchResults }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [bookData, setBookData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [bookId, setBookId] = useState("");
  const [selectedTab, setSelectedtab] = useState(0);
  const [text, setText] = useState("");
  const navigate = useNavigate();
  const modalOverlayRef = useRef(null);
  const modalRef = useRef(null);
  const ref = useRef([]);
  const locale = useStorage();
  const tabs = language.search_tabs[locale];
  const inputReference = useRef(null);

  useEffect(() => {
    animate(ref);
  }, []);

  const closeModal = () => {
    window.history.replaceState(null, null, window.location.pathname);
    localStorage.setItem("last_visited_url", window.location.pathname);
    setBookId("");
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const handleSearch = (e) => {
    const value = inputReference.current.value;
    if (value.length >= 3 || e.key === "Enter") {
      if (e.key === "Enter") inputReference.current.blur();
      setPage(1);
      searchData(value, 1);
    }
  };

  const handlePageChange = (value) => {
    const searchValue = inputReference.current.value;
    setPage((page) => (value === "back" ? page - 1 : page + 1));
    searchData(searchValue, value === "back" ? page - 1 : page + 1);
  };

  const searchData = async (value, pageNo) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "packages");
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      !selectedTab
        ? `?action=LMSsearch&search=${value}&pageno=${pageNo}`
        : selectedTab === 1
        ? `news-updates?pagination[start]=${pageNo}&pagination[limit]=5&locale=${
            localStorage.getItem("lang") || "en"
          }&filters[Heading][$contains]=${value}&populate=*`
        : "",
      selectedTab === 2 ? formData : "",
      selectedTab === 1
    );
    if (data) {
      if (!selectedTab) {
        setBookData(data.data);
        setText(language.no_books_found[locale]);
      } else if (selectedTab === 1) {
        setNewsData(data.data);
        setText(language.no_articles_found[locale]);
      } else {
        const filteredData = data.data.filter((packageData) =>
          packageData.package_name.toLowerCase().includes(value.toLowerCase())
        );
        setEventsData(filteredData);
        setText(language.no_events_found[locale]);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const value = inputReference.current.value;
    if (value.length >= 3) {
      searchData(value, 1);
    }
    setText("");
    setPage(1);
  }, [selectedTab]);

  useEffect(() => {
    const modalCl = modalRef.current?.classList;
    const overlayCl = modalOverlayRef.current?.classList;
    if (openSearchResults && modalRef.current && modalOverlayRef.current) {
      overlayCl.remove("hidden");
      setTimeout(() => {
        modalCl.remove("opacity-0");
        inputReference.current.focus();
      }, 100);
    } else {
      modalCl.add("opacity-0");
      setTimeout(() => overlayCl.add("hidden"), 100);
    }
  }, [openSearchResults]);

  return (
    <>
      <div
        ref={modalOverlayRef}
        className="flex justify-center items-center overflow-x-hidden fixed inset-0 z-50 backdrop-blur-sm hidden bg-black bg-opacity-30"
      >
        <div
          ref={modalRef}
          className="relative w-full h-screen md:min-w-full md:min-h-full md:w-auto md:h-screen md:my-0 mx-auto max-w-3xl opacity-0 transition-opacity duration-300"
        >
          <div className="bg-modalBg md:max-h-[90vh] h-full min-h-screen px-0 py-0 md:px-0 md:py-0 md:pb-[40px] border-0 md:rounded-0 shadow-lg relative flex flex-col w-full outline-none focus:outline-none overflow-scroll">
            <div className="absolute w-full z-20 bg-header-gradient flex justify-center">
              <div className="relative flex items-center w-11/12 py-[48px]">
                <input
                  ref={inputReference}
                  id="nav-search"
                  type="text"
                  className="h-10 w-full pl-10 pr-20 p-7 rounded-[30px] focus:shadow focus:outline-none"
                  placeholder={language.search[locale]}
                  onChange={(e) => handleSearch(e)}
                />
                <div className="flex ml-[1rem]">
                  <button
                    type="button"
                    class="w-[44px] h-[44px] bg-white/30 rounded-full p-2 inline-flex items-center justify-center text-gray-400"
                    onClick={() => setOpenSearchResults(false)}
                  >
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="#fff"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="pt-[140px] w-full flex justify-center">
              <div className="w-11/12 max-w-[1800px] relative">
                <div className="mb-[64px] overflow-scroll flex w-full md:justify-center text-white text-[20px]">
                  {tabs.map((tab, idx) => (
                    <div
                      className={`mx-[25px] md:mx-[64px] pb-[8px] cursor-pointer ${
                        selectedTab === idx
                          ? "border-b-[2px] border-[#D9D9D9]"
                          : "opacity-50"
                      }`}
                      onClick={() => setSelectedtab(idx)}
                    >
                      <p>{tab}</p>
                    </div>
                  ))}
                </div>

                {!selectedTab && (
                  <>
                    <div className="relative w-full grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-[12px]">
                      {loading ? (
                        <>
                          {["", "", "", ""].map((load, idx) => (
                            <div
                              role="status"
                              class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center w-full h-[300px]"
                            >
                              <div class="flex justify-center items-center w-full h-[300px] bg-gray-300 rounded dark:bg-gray-700"></div>
                              <span class="sr-only">Loading...</span>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {bookData.length > 0 ? (
                            <>
                              {bookData.map((book) => (
                                <Book
                                  openModal={openModal}
                                  book={book}
                                  setBookId={setBookId}
                                />
                              ))}
                            </>
                          ) : (
                            <div className="absolute w-full mt-[10rem]">
                              <h2 className="text-white text-center text-[22px]">
                                {text}
                              </h2>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <Modal
                      isOpen={isOpen}
                      closeModal={closeModal}
                      bookId={bookId}
                      setOpenSearchResults={setOpenSearchResults}
                    />
                  </>
                )}
                {selectedTab === 1 && (
                  <>
                    <div className="relative w-full grid grid-flow-row grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-[12px]">
                      {loading ? (
                        <>
                          {["", "", "", ""].map((load, idx) => (
                            <div
                              role="status"
                              class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center w-full h-[300px]"
                            >
                              <div class="flex justify-center items-center w-full h-[300px] bg-gray-300 rounded dark:bg-gray-700"></div>
                              <span class="sr-only">Loading...</span>
                            </div>
                          ))}
                        </>
                      ) : (
                        <>
                          {newsData.length > 0 ? (
                            <>
                              {newsData.map((news) => (
                                <Card
                                  title={news.attributes.Heading}
                                  desc={news.attributes.Content}
                                  onClick={() =>
                                    navigate(
                                      `/newsdetail/${news.attributes.slug}`
                                    )
                                  }
                                />
                              ))}
                            </>
                          ) : (
                            <div className="absolute w-full mt-[10rem]">
                              <h2 className="text-white text-center text-[22px]">
                                {text}
                              </h2>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <Modal
                      isOpen={isOpen}
                      closeModal={closeModal}
                      bookId={bookId}
                      setOpenSearchResults={setOpenSearchResults}
                    />
                  </>
                )}
                {selectedTab === 2 && (
                  <div>
                    {loading ? (
                      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-y-[24px] gap-x-[12px]">
                        {["", "", "", ""].map((load, idx) => (
                          <div
                            role="status"
                            class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center w-full h-[300px]"
                          >
                            <div class="flex justify-center items-center w-full h-[300px] bg-gray-300 rounded dark:bg-gray-700"></div>
                            <span class="sr-only">Loading...</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="relative w-full grid grid-flow-row grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-[12px]">
                        {eventsData.length > 0 ? (
                          <Events
                            title={language.events[locale]}
                            packages={eventsData}
                            ref={(el) => (ref.current[0] = el)}
                            loading={loading}
                            showTitle
                          />
                        ) : (
                          <div className="absolute w-full mt-[10rem]">
                            <h2 className="text-white text-center text-[22px]">
                              {text}
                            </h2>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {((!selectedTab && bookData.length > 0) ||
                  (selectedTab === 1 && newsData.length > 0) ||
                  (selectedTab === 2 && eventsData.length > 0)) && (
                  <Pagination handlePageChange={handlePageChange} />
                )}
                {/* <Card /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
