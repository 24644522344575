import React, { useEffect, useState, useRef } from "react";
import { debounce } from "lodash";
import moment from "moment";
import arrowdown from "../../assets/svg/arrowdown.svg";
import Book from "./Book";
import { fetchClient } from "../../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import Search from "../Search";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const BooksList = React.forwardRef(({ openModal, setBookId }, ref) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [is_Data, setIs_Data] = useState(true);
  const [eventsTab, setEventsTab] = useState(0);
  const [bookData, setBookData] = useState([]);
  const [page, setPage] = useState(1);
  const locale = useStorage();

  const handleTabChange = (index) => {
    setBookData([]);
    setEventsTab(index);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        !eventsTab
          ? "?action=LMSnewTitles&size=10&offset=50"
          : eventsTab === 1
          ? "?action=LMSpopularTitles&size=10&offset=0"
          : `?action=LMSsearch&type=genre&order=Desc&search=EIAAC&pageno=1`,
        "",
        false
      );
      if (data)
        if (eventsTab === 2) {
          setBookData(data.data);
        } else {
          setBookData(data?.data.splice(0, 8));
        }
      setLoading(false);
    };
    getData();
  }, [eventsTab]);

  useEffect(() => {
    const getData = async () => {
      setIsLoader(true);
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        `?action=LMSsearch&type=genre&order=Desc&search=EIAAC&pageno=${page}`,
        "",
        false
      );
      if (data) {
        setBookData((prevData) => [...prevData, ...data.data]);
        setIs_Data(data.data.length === 10);
      }
      setIsLoader(false);
    };
    getData();
  }, [page]);

  const handleScroll = debounce(() => {
    const scrollTarget = document.getElementById("scroll-target");
    if (scrollTarget && eventsTab === 2 && is_Data && !loading) {
      const { scrollTop, clientHeight } = document.documentElement;
      const { top, height } = scrollTarget.getBoundingClientRect();
      const distanceFromBottom = top + height - (scrollTop + clientHeight);
      if (distanceFromBottom <= 0) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  }, 200);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [eventsTab, loading, is_Data]);

  return (
    <div className="mt-[84px] w-full flex justify-center">
      <div className="w-11/12 max-w-[1800px] relative" ref={ref}>
        <div class="w-full h-[50px] bg-[#ffffff4d] rounded-[30px] px-[15px] py-[12px] mb-[50px] md:flex items-center">
          <input
            class="text-[16px] placeholder-white text-white bg-transparent border-none outline-none w-full"
            placeholder={language.search[locale]}
            onClick={() => setOpenSearchResults(true)}
          />
        </div>
        <Search
          openSearchResults={openSearchResults}
          setOpenSearchResults={setOpenSearchResults}
        />
        <div className="flex justify-between md:justify-center mb-[36px] flex-col md:flex-row">
          <ul className="flex text-[20px] md:text-[32px] gap-[36px] w-full overflow-scroll">
            <li
              className={`text-white cursor-pointer pb-[8px] ${
                eventsTab === 0 ? "border-b-[1px] border-white" : "opacity-50"
              }`}
              onClick={() => handleTabChange(0)}
            >
              {language.new_releases[locale]}
            </li>
            <li
              className={`text-white cursor-pointer pb-[8px] ${
                eventsTab === 1 ? "border-b-[1px] border-white" : "opacity-50"
              }`}
              onClick={() => handleTabChange(1)}
            >
              {language.most_popular[locale]}
            </li>
            <li
              className={`text-white cursor-pointer pb-[8px] ${
                eventsTab === 2 ? "border-b-[1px] border-white" : "opacity-50"
              }`}
              onClick={() => handleTabChange(2)}
            >
              {language.digitized_book[locale]}
            </li>
          </ul>
          <div className="hidden py-[16px] px-[24px] bg-primaryBg rounded-[100px] text-white flex gap-[16px] text-[16px] w-fit mt-[36px] md:mt-0">
            {" "}
            <span className="opacity-50"> Filter </span>{" "}
            <img src={arrowdown} alt="" className="w-[13px] opacity-50" />{" "}
          </div>
        </div>
        <p className="text-white text-[17px] mb-[20px]">
          {language.discover_shelves[locale]}
        </p>

        <div className="w-full grid grid-flow-row grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-[12px] mt-[2rem ]">
          {!loading ? (
            <>
              {bookData.map((book) => (
                <Book openModal={openModal} book={book} setBookId={setBookId} />
              ))}
            </>
          ) : (
            <>
              {[...Array(4)].map(() => (
                <div
                  role="status"
                  class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center w-full h-[440px] md:h-[600px]"
                >
                  <div class="flex justify-center items-center w-full h-[440px] md:h-[600px] bg-gray-300 rounded dark:bg-gray-700"></div>
                  <span class="sr-only">Loading...</span>
                </div>
              ))}
            </>
          )}
        </div>
        {isLoader && (
          <div role="status" className="flex justify-center w-full items-end">
            <svg
              aria-hidden="true"
              class="me-2 w-8 h-8 text-[#616161] animate-spin fill-white"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
});

export default BooksList;
