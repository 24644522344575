import React, { useEffect, useRef, useState } from "react";
import Banner from "../components/Banner";
import { useDraggable } from "react-use-draggable-scroll";
import BannerImage from "../assets/images/ATAW_2023_Banner.png";
import leftslide from "../assets/images/leftslide.png";
import rightslide from "../assets/images/rightslide.png";
import image1 from "../assets/images/Summer_Camp/484A3498.jpg";
import image2 from "../assets/images/Summer_Camp/484A3518.jpg";
import image3 from "../assets/images/Summer_Camp/484A3601.jpg";
import image4 from "../assets/images/Summer_Camp/484A4244.jpg";
import image5 from "../assets/images/Summer_Camp/484A4335.jpg";
import image6 from "../assets/images/Summer_Camp/484A5666.jpg";
import image7 from "../assets/images/Summer_Camp/484A5971.jpg";
import image8 from "../assets/images/Summer_Camp/484A6018.jpg";
import image9 from "../assets/images/Summer_Camp/484A6080.jpg";
import image10 from "../assets/images/Summer_Camp/484A6168.jpg";
import { Link } from "react-router-dom";

const SummerCamp = () => {
  const scrollref = useRef();
  const { events } = useDraggable(scrollref);
  const [width, setWidth] = useState(window.innerWidth);

  const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
  ];

  const week1List = [
    {
      name: "Egypt",
      pts: [
        "•	Learn about Egypt’s culture and heritage",
        "•	Let’s make a delicious basbousa dessert",
        "•	Create a papyrus scroll painting",
        "•	Experience palaeontology fossils from Egypt",
      ],
    },
    {
      name: "Ghana",
      pts: [
        "•	Learn about Ghana’s culture and heritage",
        "•	Let’s whip up ‘strawberries and cream’ dessert",
        "•	Create an Adinkra block printing on cloth",
        "•	Listen to a Ghanian drumming session",
      ],
    },
    {
      name: "Fiji",
      pts: [
        "•	Learn about Fiji’s culture and heritage",
        "•	Let’s make a refreshing Fijian fruit salad",
        "•	Create an Adinkra block printing on cloth",
        "•	Meeting Fijian animals at our petting zoo",
      ],
    },
    {
      name: "Saudia Arabia",
      pts: [
        "•	Learn about Saudia Arabia’s culture and heritage",
        "•	Let’s make a healthy ‘dates with coconut’ dessert",
        "•	Create a bedouin-inspired carpet wall display",
        "•	Learn the art of Arabic calligraphy",
      ],
    },
  ];

  const week2List = [
    {
      name: "South Korea",
      pts: [
        "•	Learn about South Korea’s culture and heritage",
        "•	Let’s make a yummy ‘kimbap’ roll",
        "•	Create a Korean mobile phone holder (Najeon Griptok)",
        "•	Watch a K-pop dancing show",
      ],
    },
    {
      name: "Thailand",
      pts: [
        "•	Learn about Thailand’s culture and heritage",
        "•	Let’s make a ‘mango sticky rice’ dessert",
        "•	Lasercut 3D elephant",
        "•	Experience the art of Muay Thai",
      ],
    },
    {
      name: "Mexico",
      pts: [
        "•	Learn about Mexico’s culture and heritage",
        "•	Let’s make some creamy guacamole",
        "•	Personalised Mexican maracas",
        "•	Mariachi musical performance",
      ],
    },
    {
      name: "Cuba",
      pts: [
        "•	Learn about Cuba’s culture and heritage",
        "•	Let’s bake Cuban shortbread cookies",
        "•	Cuban culture terrarium",
        "•	Cuban conga drumming session",
      ],
    },
  ];

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () => setWidth(window.innerWidth));
    };
  }, []);

  return (
    <>
      <Banner url={BannerImage} title="" desc="" />
      <div className="w-full flex justify-center">
        <div className="sm:w-8/12 md:w-5/12 w-11/12 max-w-[2000px]">
          <p className="text-white text-[18px] my-[4rem] text-justify">
            Are you ready for an exciting adventure? Well, hop onboard because
            we're taking you on a tour of 8 countries around the world! This
            summer, we'll be visiting Egypt, Ghana, Fiji, Saudi Arabia, South
            Korea, Thailand, Mexico, and Cuba. Along the way, we'll learn about
            each country’s culture, language, food, geography, wildlife, and
            landmarks. We'll also have lots of fun playing games, singing songs,
            doing arts and crafts, and cooking traditional foods!
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Link
          to="/happenings"
          className="text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[1rem]"
        >
          Register for Summer Camp
        </Link>
      </div>
      <div className="w-full h-full flex justify-center">
        <div className="w-11/12 h-full max-w-[1800px] mt-[30px]">
          <div className="w-full sm:flex-col md:flex-row flex flex-col gap-[1rem] mt-[3rem]">
            <div
              style={{
                backgroundImage:
                  "linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%))",
              }}
              className="bg-image-gradient w-full h-[820px] max-h-[850px] rounded-[8px] relative flex flex-col items-center justify-center"
            >
              <p className="text-white text-center mt-[2rem] text-[28px]">
                Week 1
              </p>
              <div className="mt-[1rem] text-left">
                {week1List.map((week1) => (
                  <>
                    <div
                      key={week1.name}
                      className="text-white text-[18px] font-bold mt-[2rem]"
                    >
                      {week1.name}
                    </div>
                    <div>
                      {week1.pts.map((pt, index) => (
                        <p key={index} className="text-white text-left">
                          {pt}
                        </p>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            </div>
            <div
              style={{
                backgroundImage:
                  "linear-gradient(rgb(0 0 0 / 50%), rgb(0 0 0 / 50%))",
              }}
              className="bg-image-gradient w-full h-[820px] max-h-[850px] rounded-[8px] relative flex flex-col items-center justify-center"
            >
              <p className="text-white text-center mt-[2rem] text-[28px]">
                Week 2
              </p>
              <div className="mt-[1rem] text-left">
                {week2List.map((week2) => (
                  <>
                    <div
                      key={week2.name}
                      className="text-white text-[18px] font-bold mt-[2rem]"
                    >
                      {week2.name}
                    </div>
                    <div>
                      {week2.pts.map((pt, index) => (
                        <p key={index} className="text-white text-left">
                          {pt}
                        </p>
                      ))}
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mt-[2rem]">
        <Link
          to="/happenings"
          className="text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[1rem]"
        >
          Register for Summer Camp
        </Link>
      </div>
      <div className="w-full flex justify-center mt-[2rem]">
        <div className="relative w-11/12 h-full max-w-[1800px]">
          <p className="text-white text-[32px] mt-[2rem] mb-[2rem]">Gallery</p>
          <button
            onClick={() =>
              document.getElementById("gallery").scrollBy({
                left: width > 820 ? -1000 : -360,
                behavior: "smooth",
              })
            }
            className="absolute top-[55%] left-0 text-white w-10 text-center z-10 p-0 ml-[-15px] transition-all ease-in-out duration-300"
          >
            <img src={leftslide} alt="leftslide" />
          </button>
          <div
            id="gallery"
            className="w-full flex gap-[12px] overflow-x-scroll cursor-pointer"
            {...events}
            ref={scrollref}
          >
            {images.map((image) => (
              <div
                className={`w-[450px] h-[293px] rounded-[8px] p-[24px] md:p-[48px] flex items-end justify-start shrink-0`}
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></div>
            ))}
          </div>
          <button
            onClick={() =>
              document.getElementById("gallery").scrollBy({
                left: width > 820 ? 1000 : 360,
                behavior: "smooth",
              })
            }
            className="absolute top-[55%] right-0 text-white w-10 text-center z-10 p-0 mr-[-15px] transition-all ease-in-out duration-300"
          >
            <img src={rightslide} alt="rightslide" />
          </button>
        </div>
      </div>
    </>
  );
};

export default SummerCamp;
