import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "./Books/Modal";
import { fetchClient } from "../axios-config";
import PackageDetails from "./PackageDetails/PackageDetails";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { FETCH_DATA } from "../redux/action";
import ReservePods from "./ReservePods/ReservePods";

export default function ComponentWrapper({ children }) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [bookOpen, setBookOpen] = useState(false);
  const [packageOpen, setPackageOpen] = useState(false);
  const [reservePodOpen, setReservePodOpen] = useState(false);
  const [data, setData] = useState(null);
  const [packageSlug, setPackageSlug] = useState("");
  const [bookId, setBookId] = useState("");
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    const getData = async () => {
      const resData = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        `top-hero-section?populate=home.image&populate=read.image&populate=happenings.image&populate=reserve.image&populate=dine.image&populate=about.image&populate=membership.image&populate=bookclub.image`,
        "",
        true
      );
      // window.removeEventListener("storage", getData);
      if (resData)
        dispatch({
          type: FETCH_DATA,
          payload: resData.data.attributes,
        });
    };
    getData();

    // window.addEventListener("storage", getData);

    // return () => {
    //   window.removeEventListener("storage", getData);
    // };
  }, []);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    var type = params.get("type");
    var uuid = params.get("uuid");
    if (type === "package") {
      setPackageSlug(uuid);
      setPackageOpen(true);
      return;
    }
    if (type === "library") {
      setBookId(uuid);
      setBookOpen(true);
      return;
    }
    if (type === "privatepods") {
      setReservePodOpen(true);
      return;
    }
  }, [window.location.search]);

  useEffect(() => {
    if (bookOpen || packageOpen || reservePodOpen) {
      document
        .getElementsByTagName("body")[0]
        ?.classList.add("overflow-hidden");
    } else {
      document
        .getElementsByTagName("body")[0]
        ?.classList.remove("overflow-hidden");
    }
  }, [bookOpen, packageOpen, reservePodOpen]);

  const closeBookModal = () => {
    window.history.replaceState(null, null, window.location.pathname);
    setBookId("");
    setBookOpen(false);
  };

  return (
    <div>
      {children}
      <Modal isOpen={bookOpen} closeModal={closeBookModal} bookId={bookId} />
      <PackageDetails
        setPackageSlug={setPackageSlug}
        packageSlug={packageSlug}
        isOpen={packageOpen}
        closeModal={() => {
          window.history.replaceState(null, null, window.location.pathname);
          setPackageOpen(false);
        }}
      />
      <ReservePods
        isOpen={reservePodOpen}
        closeModal={() => {
          window.history.replaceState(null, null, window.location.pathname);
          setReservePodOpen(false);
        }}
      />
    </div>
  );
}
