import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Banner from "../components/NewsDetail/Banner";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchClient } from "../axios-config";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

function NewsDetail() {
  const params = useParams();
  const [news, setNews] = useState();
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const locale = useStorage();

  useEffect(() => {
    const getNewsData = async () => {
      setLoading(true);
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        `news-updates?&locale=${
          localStorage.getItem("lang") || "en"
        }&filters[slug][$eq]=${params.news_slug}&populate=*`,
        "",
        true
      );
      if (data) setNews(data.data[0].attributes);
      setLoading(false);
    };
    getNewsData();
  }, [params]);

  return (
    <>
      <Banner
        url="[--bg-url:url('./assets/images/happenings.webp')]"
        // title="10 Dec 2022"
        desc={news?.Heading}
      />
      <div className="mt-[84px] w-full flex justify-center">
        {loading ? (
          <div className="w-10/12 md:w-8/12 relative">
            <div
              role="status"
              class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex mb-[48px]"
            >
              <div class="w-full">
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 w-48  mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="w-10/12 md:w-8/12 relative">
            <div
              className="text-white text-[16px]"
              dangerouslySetInnerHTML={{
                __html: news?.Content,
              }}
            ></div>
            <button className="hidden flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] rounded-[24px] w-fit relative border-[#FFFFFF33] mt-[60px] border-[1.5px]">
              {language.share[locale]}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default NewsDetail;
