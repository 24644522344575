import React, { useEffect, useRef, useState } from "react";
import Banner from "../components/Banner";
import animate from "../gsap/animate";
import Card from "../components/Card";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchClient } from "../axios-config";
import Pagination from "../components/Pagination";
import { useNavigate } from "react-router-dom";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

const News = () => {
  const ref = useRef([]);
  const [newsData, setNewsData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const locale = useStorage();

  useEffect(() => {
    animate(ref);
    getNewsData(page);
  }, []);

  const handlePageChange = (value) => {
    setPage((page) => (value === "back" ? page - 1 : page + 1));
    getNewsData(value === "back" ? page - 1 : page + 1);
  };

  const getNewsData = async (page) => {
    setLoading(true);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      `news-updates?pagination[start]=${page}&pagination[limit]=5&locale=${
        localStorage.getItem("lang") || "en"
      }&populate=*`,
      "",
      true
    );
    if (data) setNewsData(data.data);
    setLoading(false);
  };

  return (
    <>
      <Banner
        url="[--bg-url:url('./assets/images/happenings.webp')]"
        title={language.news[locale]}
        desc=""
      />
      <div className="mt-[60px] w-full flex justify-center">
        {loading ? (
          <div className="w-11/12 max-w-[1800px] relative">
            {[...Array(3)].map(() => (
              <div
                role="status"
                class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex mb-[48px]"
              >
                <div class="flex items-center justify-center w-full bg-gray-300 md:w-[80%] rounded-[8px] h-[400px] dark:bg-gray-700"></div>
                <div class="w-full">
                  <div class="h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                  <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                </div>
                <span class="sr-only">Loading...</span>
              </div>
            ))}
          </div>
        ) : (
          <div className="w-11/12 max-w-[1800px] relative">
            {newsData.map((news) => (
              <Card
                title={news.attributes.Heading}
                desc={news.attributes.Content}
                img={news.attributes.Featured_Image.data?.attributes.url}
                onClick={() => navigate(`/newsdetail/${news.attributes.slug}`)}
              />
            ))}
          </div>
        )}
      </div>
      {newsData.length > 0 && (
        <Pagination handlePageChange={handlePageChange} />
      )}
    </>
  );
};

export default News;
