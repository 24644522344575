import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import close from "../../assets/svg/close.svg";
import FormSteps from "./FormSteps";
import Calendar from "./Calendar";
import FluentFood from "./FluentFood";
import SubPackages from "./SubPackages";
import TimeSlot from "./TimeSlot";
import Transport from "./Transport";
import EventDetails from "./EventDetails";
import Users from "./Users";
import Summary from "./Summary";
import { ReactComponent as Guests } from "../../assets/svg/reserve_guests.svg";
import { ReactComponent as Dates } from "../../assets/svg/reserve_date.svg";
import { ReactComponent as Time } from "../../assets/svg/reserve_time.svg";
import { ReactComponent as List } from "../../assets/svg/list-svgrepo-com.svg";
import { ReactComponent as UserGroup } from "../../assets/svg/users-group-svgrepo-com.svg";
import moment from "moment";
import { fetchClient } from "../../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import PaymentModal from "../PaymentModal";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";
import Details from "./Details";
import SummerDate from "./SummerDate";

const Modal = ({ isOpen, closeModal, packageSlug, setPackageSlug }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [step, setStep] = useState(-1);
  const [stepList, setStepList] = useState([]);
  const [packageDetails, setPackageDetails] = useState();
  const [packageSlots, setPackageSlots] = useState();
  const [dateAvailability, setDateAvailability] = useState();
  const [couponValidityData, setCouponValidityData] = useState();
  const [addChild, setAddChild] = useState(0);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [purchaseData, setPurchaseData] = useState();
  const [addPerson, setAddPerson] = useState(0);
  const [showTimeSlot, setShowTimeSlot] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [realDate, setRealDate] = useState("");
  const [adultPrice, setAdultPrice] = useState("");
  const [childPrice, setChildPrice] = useState("");
  const [adultLimitMax, setAdultLimitMax] = useState(99);
  const [adultLimitMin, setAdultLimitMin] = useState(1);
  const [childLimitMax, setChildLimitMax] = useState(99);
  const [childLimitMin, setChildLimitMin] = useState(0);
  const [subPackages, setSubPackages] = useState([]);
  const [meals, setMeals] = useState([]);
  const [transports, setTransports] = useState([]);
  const [isOneWay, setOneWay] = useState(true);
  const [transport_id, setTransportID] = useState(0);
  const [transport_name, setTransportName] = useState("");
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(-1);
  const [timeSlotValue, setTimeSlotValue] = useState("");
  const [time_slot, setTimeSlot] = useState("");
  const [btnPackageName, setPackageName] = useState("SELECT PACKAGE");
  const [btnMealName, setMealName] = useState("SELECT MEALS");
  const [packagePrice, setPackagePrice] = useState(0);
  const [subpackages_adult_qty, setSubAdultQty] = useState(0);
  const [subpackages_child_qty, setSubChildQty] = useState(0);
  const [mealPrice, setMealPrice] = useState(0);
  const [transportPrice, setTransportPrice] = useState(0);
  const [isBooking, showBooking] = useState(false);
  const [isDateSelector, showDateSelector] = useState(false);
  const [isTimeSlotSelector, showTimeSlotSelector] = useState(false);
  const [isSubPackageSelector, showSubPackageSelector] = useState(false);
  const [isMealSelector, showMealSelector] = useState(false);
  const [isTransportSelector, showTransportSelector] = useState(false);
  const [progressCount, setProgressCount] = useState(1);
  const [progressValue, setProgressValue] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const [total_adult_tickets, setTotalAdultTickets] = useState(0);
  const [total_adult_time, setTotalAdultTime] = useState(0);
  const [total_child_tickets, setTotalChildTickets] = useState(0);
  const [total_child_time, setTotalChildTime] = useState(0);
  const [isAlert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [showDetails, isShowDetails] = useState(false);
  const [wobble, setWobble] = useState(0);
  const [coupon_id, setCouponID] = useState("");
  const [enable_date_picker, setEnableDatePicker] = useState(false);
  const [enable_time_picker, setEnableTimePicker] = useState(false);
  const [enable_meal, setEnableMeal] = useState(false);
  const [enable_sub_packages, setEnableSubPackages] = useState(false);
  const [enable_transport, setEnableTransport] = useState(false);
  const [offValue, setOffValue] = useState(0);
  const [maxDiscountVal, setMaxDiscountVal] = useState(0);
  const [minCart, setMinCart] = useState(0);
  const [total, setTotal] = useState(0);
  const [offType, setOffType] = useState("");
  const [skipAddon, setSkipAddon] = useState(0);
  const [skipTranport, setSkipTransport] = useState(0);
  const [skipFood, setSkipFood] = useState(0);
  const [couponBuy, setCouponBuy] = useState("");
  const [couponBuyQty, setCouponBuyQty] = useState(0);
  const [couponGet, setCouponGet] = useState("");
  const [couponGetQty, setCouponGetQty] = useState(0);
  const [couponValue, setCouponValue] = useState("");
  const [showCoupon, isShowCoupon] = useState(false);
  const [isCoupon, setCoupon] = useState(false);
  const [realCoupon, setRealCoupon] = useState("");
  const [disabled_dates, setDisabledDates] = useState([]);
  const [disabled_weeks, setDisabledWeeks] = useState([]);
  const [isSubTitle, showSubTitle] = useState([]);
  const [isMealTitle, showMealTitle] = useState([]);
  const [adult_overall_limit, setAdultOverAllLimit] = useState("");
  const [details, setDetails] = useState({
    name: "",
    dob: "",
  });
  const [openModal, setOpenModal] = useState(false);
  const [child_overall_limit, setChildOverAllLimit] = useState("");
  const [adult_booked_times, setAdultBookedTimes] = useState("");
  const [child_booked_times, setChildBookedTimes] = useState("");
  const [isLoad, setLoad] = useState(0);
  const [pAlert, setpAlert] = useState(1);
  const [shipping, setShipping] = useState(false);
  const [attendeeAlert, setAttendeeAlert] = useState("");
  const [collectDetails, setCollectDetails] = useState(false);
  const [tempCouponValue, setTempCouponValue] = useState("");
  const [customChildValue, setCustomChildValue] = useState([]);
  const [selectedSummerDate, setSelectedSummerDate] = useState([]);
  const [selectedSummerGroup, setSelectedSummerGroup] = useState("");
  const [summerDates, setSummerDates] = useState([]);
  const [summerGroup, setSummerGroup] = useState([]);
  const [summerCamp, setSummerCamp] = useState(false);
  const [showDates, setShowDates] = useState(false);
  const [customChildPrice, setCustomChildPrice] = useState("0");
  const [customAdultPrice, setCustomAdultPrice] = useState("0");
  const [attendeesList, setAttendessList] = useState([]);
  const location = useLocation();
  const locale = useStorage();

  const [data, setData] = useState([]);

  const resetCloseModal = () => {
    setStep(-1);
    setStepList([]);
    setPackageDetails();
    setPackageSlots();
    setDateAvailability();
    setCouponValidityData();
    setAddChild(0);
    setAddPerson(0);
    setShowTimeSlot();
    setSelectedDate("");
    setRealDate("");
    setAdultPrice("");
    setChildPrice("");
    setAdultLimitMax(99);
    setAdultLimitMin(1);
    setChildLimitMax(99);
    setChildLimitMin(0);
    setSubPackages([]);
    setMeals([]);
    setTransports([]);
    setOneWay(true);
    setTransportID(0);
    setTransportName("");
    setTimeSlots([]);
    setSelectedTimeSlot(-1);
    setOpenPaymentModal(false);
    setPurchaseData();
    setTimeSlotValue("");
    setTimeSlot("");
    setPackageName("SELECT PACKAGE");
    setMealName("SELECT MEALS");
    setPackagePrice(0);
    setSubAdultQty(0);
    setSubChildQty(0);
    setMealPrice(0);
    setTransportPrice(0);
    showBooking(false);
    showDateSelector(false);
    showTimeSlotSelector(false);
    showSubPackageSelector(false);
    showMealSelector(false);
    showTransportSelector(false);
    setProgressCount(1);
    setProgressValue(100);
    setLoading(false);
    setTotalAdultTickets(0);
    setTotalAdultTime(0);
    setTotalChildTickets(0);
    setTotalChildTime(0);
    setAlert(false);
    setAlertText("");
    isShowDetails(false);
    setWobble(0);
    setCouponID("");
    setEnableDatePicker(false);
    setEnableTimePicker(false);
    setEnableMeal(false);
    setEnableSubPackages(false);
    setEnableTransport(false);
    setOffValue(0);
    setMaxDiscountVal(0);
    setMinCart(0);
    setTotal(0);
    setOffType("");
    setSkipAddon(0);
    setSkipTransport(0);
    setSkipFood(0);
    setCouponBuy("");
    setCouponBuyQty(0);
    setCouponGet("");
    setCouponGetQty(0);
    setCouponValue("");
    isShowCoupon(false);
    setCoupon(false);
    setRealCoupon("");
    setDisabledDates([]);
    setDisabledWeeks([]);
    showSubTitle([]);
    showMealTitle([]);
    setAdultOverAllLimit("");
    setChildOverAllLimit("");
    setAdultBookedTimes("");
    setChildBookedTimes("");
    setLoad(0);
    setpAlert(1);
    setTempCouponValue("");
    setCustomChildValue([]);
    setPackageSlug("");
    setShipping(false);
    setCollectDetails(false);
    setSummerCamp(false);
    setSummerDates([]);
    setSummerGroup([]);
    closeModal();
  };

  useEffect(() => {
    const temp = [];
    temp.push(<Guests fill={step === 0 && "#111"} step={0} />);
    if (collectDetails) {
      temp.push(<UserGroup fill={step === 1 && "#111"} step={1} />);
    }
    if (enable_date_picker) {
      temp.push(<Dates fill={step === 2 && "#111"} step={2} />);
    }
    if (summerCamp) {
      temp.push(<List fill={step === 3 && "#111"} step={3} />);
    }
    if (enable_time_picker) {
      temp.push(<Time fill={step === 4 && "#111"} step={4} />);
    }
    setData(temp);
  }, [enable_date_picker, enable_time_picker, collectDetails, summerCamp]);

  let isMeal = false,
    isSub = false,
    isTime = false,
    isDate = false,
    isTransport = false;

  useEffect(() => {
    if (packageSlug) {
      const getPackageDetails = async () => {
        const formData = new FormData();
        formData.append("action", "packagesDetails");
        formData.append("slug", packageSlug);
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          "",
          formData,
          false
        );
        if (data) setPackageDetails(data.data);
      };
      getPackageDetails();
    }
  }, [packageSlug]);

  let minDate = new Date(packageDetails?.start_date);

  let maxDate = new Date(packageDetails?.end_date);

  // useEffect(() => {
  //   let index = 2;
  //   let temp = [];
  //   temp.push({
  //     id: 1,
  //     value: "user",
  //     icon: <i className="far fa-user booking-flow-icon"></i>,
  //     detail:
  //       packageDetails?.id === "2501" ||
  //       packageDetails?.id === "2502" ||
  //       packageDetails?.id === "2503" ||
  //       packageDetails?.id === "2504" ||
  //       packageDetails?.id === "2505" ||
  //       packageDetails?.id === "2596" ||
  //       packageDetails?.id === "2595" ||
  //       packageDetails?.id === "2633" ||
  //       packageDetails?.id === "2634"
  //         ? addPerson *
  //             (packageDetails?.id === "2501" ||
  //             packageDetails?.id === "2502" ||
  //             packageDetails?.id === "2503" ||
  //             packageDetails?.id === "2504" ||
  //             packageDetails?.id === "2505" ||
  //             packageDetails?.id === "2596" ||
  //             packageDetails?.id === "2634"
  //               ? customChildValue.reduce(function (sum, item) {
  //                   return Number(sum) + Number(item.price);
  //                 }, 0)
  //               : adultPrice) +
  //           addChild *
  //             (packageDetails?.id === "2595" || packageDetails?.id === "2633"
  //               ? customChildValue.reduce(function (sum, item) {
  //                   return Number(sum) + Number(item.price);
  //                 }, 0)
  //               : childPrice)
  //         : addPerson * adultPrice + addChild * childPrice,
  //   });
  //   if (enable_date_picker) {
  //     temp.push({
  //       id: index,
  //       value: "date",
  //       icon: <i className="far fa-calendar-alt booking-flow-icon"></i>,
  //       detail: realDate,
  //     });
  //     index++;
  //   }
  //   if (enable_time_picker) {
  //     temp.push({
  //       id: index,
  //       value: "time",
  //       icon: <i className="far fa-clock  booking-flow-icon"></i>,
  //       detail: time_slot,
  //     });
  //     index++;
  //   }
  //   if (enable_sub_packages) {
  //     temp.push({
  //       id: index,
  //       value: "sub_packages",
  //       icon: <i className="far fa-plus-square   booking-flow-icon"></i>,
  //       detail: packagePrice,
  //     });
  //     index++;
  //   }
  //   if (enable_transport) {
  //     temp.push({
  //       id: index,
  //       value: "transports",
  //       icon: <i className="fas fa-car booking-flow-icon"></i>,
  //       detail: transportPrice,
  //     });
  //     index++;
  //   }
  //   if (enable_meal) {
  //     temp.push({
  //       id: index,
  //       value: "meals",
  //       icon: <i className="fas fa-utensils booking-flow-icon"></i>,
  //       detail: mealPrice,
  //     });
  //   }
  //   setStepList(temp);
  // }, [
  //   enable_date_picker,
  //   enable_time_picker,
  //   packageDetails,
  //   realDate,
  //   packagePrice,
  //   mealPrice,
  //   time_slot,
  //   timeSlotValue,
  //   transportPrice,
  //   enable_sub_packages,
  //   enable_meal,
  //   enable_transport,
  // ]);

  useEffect(() => {
    console.log(packageDetails);
    if (packageDetails) {
      if (packageDetails?.price) {
        setAdultPrice(packageDetails?.price);
      }
      if (packageDetails?.child_price) {
        setChildPrice(packageDetails?.child_price);
      }
      if (packageDetails?.adult_default_qty) {
        setAddPerson(Number(packageDetails?.adult_default_qty));
      }
      if (packageDetails?.child_default_qty) {
        setAddChild(Number(packageDetails?.child_default_qty));
      }
      if (packageDetails?.collect_participant_details === "1") {
        setCollectDetails(true);
      }
      if (packageDetails?.summer_camp === "1") {
        setSummerCamp(true);
        setSummerDates(packageDetails.summer_camp_event_dates);
        setSummerGroup(packageDetails.summer_camp_groups);
      }
      if (
        packageDetails?.enable_date_picker != "1" &&
        packageDetails?.enable_meal != "1" &&
        packageDetails?.enable_sub_packages != "1" &&
        packageDetails?.enable_time_picker != "1" &&
        packageDetails?.enable_transport != "1"
      ) {
        showBooking(true);
      }
      let disabled_dates_data = [];

      if (packageDetails?.event_dates?.length) {
        const event_date = packageDetails?.event_dates;
        var currDate = new Date();
        var newDate = new Date(
          currDate.getFullYear() + 1,
          currDate.getMonth(),
          currDate.getDate()
        );
        const yearData = [...event_date].map((Edate) =>
          moment(Edate).format("YYYY-MM-DD")
        );
        for (var d = new Date(); d <= newDate; d.setDate(d.getDate() + 1)) {
          yearData.push(moment(d).format("YYYY-MM-DD"));
        }
        const result = yearData.filter(function (e) {
          return yearData.indexOf(e) == yearData.lastIndexOf(e);
        });
        const convertedDate = result.map(
          (re) =>
            new Date(
              Number(re.split("-")[0]),
              Number(re.split("-")[1]) - 1,
              Number(re.split("-")[2])
            )
        );
        disabled_dates_data.push(...convertedDate);
      }

      if (packageDetails?.disabled_dates?.length) {
        disabled_dates_data.push(...packageDetails?.disabled_dates);
      }

      if (packageDetails?.disable_booking_prior == "1") {
        const curDate = new Date();

        if (curDate.getHours() >= 1) {
          let day = curDate.getDate() + 1;
          let date =
            curDate.getMonth() + 1 + "/" + day + "/" + curDate.getFullYear();
          let date1 =
            curDate.getMonth() +
            1 +
            "/" +
            curDate.getDate() +
            "/" +
            curDate.getFullYear();
          disabled_dates_data.push(date);
          disabled_dates_data.push(date1);
        } else {
          let date =
            curDate.getMonth() +
            1 +
            "/" +
            curDate.getDate() +
            "/" +
            curDate.getFullYear();
          disabled_dates_data.push(date);
        }
      }

      setDisabledDates(disabled_dates_data);
      if (packageDetails?.disabled_weeks) {
        let weeks = (packageDetails?.disabled_weeks).split(",");
        for (var i = 0; i < weeks.length; i++) {
          if (weeks[i] == "monday") {
            setDisabledWeeks((weeks) => [...weeks, 1]);
          } else if (weeks[i] == "tuesday") {
            setDisabledWeeks((weeks) => [...weeks, 2]);
          } else if (weeks[i] == "wednesday") {
            setDisabledWeeks((weeks) => [...weeks, 3]);
          } else if (weeks[i] == "thursday") {
            setDisabledWeeks((weeks) => [...weeks, 4]);
          } else if (weeks[i] == "friday") {
            setDisabledWeeks((weeks) => [...weeks, 5]);
          } else if (weeks[i] == "saturday") {
            setDisabledWeeks((weeks) => [...weeks, 6]);
          } else if (weeks[i] == "sunday") {
            setDisabledWeeks((weeks) => [...weeks, 0]);
          }
        }
      }
      if (packageDetails?.id) {
        const dateCheck = new FormData();
        dateCheck.append("action", "dateAvailabilityCheck");
        dateCheck.append("id", packageDetails?.id);
        dateCheck.append("date", moment(new Date()).format("MM/DD/YYYY"));
        getDateAvailability(dateCheck);
        const slotsData = new FormData();
        slotsData.append("action", "packageSlots");
        slotsData.append("id", packageDetails?.id);
        getPackageSlots(slotsData);
      }
      let count = 6;
      let flag = false;
      if (packageDetails?.enable_date_picker == "1") {
        flag = true;
      }
      if (!flag) {
        setEnableDatePicker(false);
        count--;
      } else {
        setEnableDatePicker(true);
        flag = false;
      }
      setSubPackages([]);
      if (packageDetails?.enable_sub_packages == "1") {
        for (let i = 0; i < packageDetails?.sub_packages.length; i++) {
          if (
            packageDetails?.sub_packages[i].enable == "1" &&
            packageDetails?.sub_packages[i].package_name &&
            packageDetails?.sub_packages[i].package_name.length > 2 &&
            packageDetails?.sub_packages[i].adult_price
          ) {
            flag = true;
            setSubPackages((subPackages) => [
              ...subPackages,
              {
                value: `${packageDetails?.sub_packages[i]?.package_name}`,
                label: `${packageDetails?.sub_packages[i]?.package_name}`,
                adult_price: `${packageDetails?.sub_packages[i]?.adult_price}`,
                child_price: `${packageDetails?.sub_packages[i]?.child_price}`,
                adult_person: 0,
                id: `${packageDetails?.sub_packages[i]?.id}`,
                child_person: 0,
              },
            ]);
          }
        }
      }
      if (!flag) {
        setEnableSubPackages(false);
        count--;
      } else {
        setEnableSubPackages(true);
        flag = false;
      }
      setMeals([]);
      if (packageDetails?.enable_meal == "1") {
        for (let i = 0; i < packageDetails?.meals.length; i++) {
          if (
            packageDetails?.meals[i].enable == "1" &&
            packageDetails?.meals[i].meal_name &&
            packageDetails?.meals[i].meal_name.length > 1 &&
            packageDetails?.meals[i].price
          ) {
            flag = true;
            setMeals((meals) => [
              ...meals,
              {
                value: `${packageDetails?.meals[i]?.meal_name}`,
                label: `${packageDetails?.meals[i]?.meal_name}`,
                price: `${packageDetails?.meals[i]?.price}`,
                person: 0,
                description: `${packageDetails?.meals[i]?.description}`,
                id: `${packageDetails?.meals[i]?.id}`,
              },
            ]);
          }
        }
      }
      if (!flag) {
        setEnableMeal(false);
        count--;
      } else {
        setEnableMeal(true);
        flag = false;
      }
      setTransports([]);
      if (packageDetails?.enable_transport == "1") {
        for (let i = 0; i < packageDetails?.transport.length; i++) {
          if (
            packageDetails?.transport[i].enable == "1" &&
            packageDetails?.transport[i].name.length > 1 &&
            packageDetails?.transport[i].one_way.length > 0
          ) {
            flag = true;
            setTransports((transport) => [
              ...transport,
              {
                value: `${packageDetails?.transport[i]?.name}`,
                label: `${packageDetails?.transport[i]?.name}`,
                one_way: `${packageDetails?.transport[i]?.one_way}`,
                two_way: `${packageDetails?.transport[i]?.two_way}`,
                id: `${packageDetails?.transport[i]?.id}`,
              },
            ]);
          }
        }
      }
      if (!flag) {
        setEnableTransport(false);
        count--;
      } else {
        setEnableTransport(true);
        flag = false;
      }
      if (packageDetails?.enable_time_picker == "1") {
        flag = true;
      }
      if (!flag) {
        setEnableTimePicker(false);
        count--;
      } else {
        setEnableTimePicker(true);
        flag = false;
      }
      setProgressCount(count);
      if (count > 1) {
        setProgressCount(count);
        setProgressValue(100 / count);
        showBooking(false);
      } else {
        setProgressValue(100);
      }
      if (timeSlots && timeSlots[0]?.value) {
        setTimeSlotValue(timeSlots[0].value.slice(0, 7));
      }
      if (packageDetails?.adult_booked_times) {
        setAdultBookedTimes(packageDetails?.adult_booked_times);
      }
      if (packageDetails?.adult_overall_limit) {
        setAdultOverAllLimit(packageDetails?.adult_overall_limit);
      }
      if (packageDetails?.child_booked_times) {
        setChildBookedTimes(packageDetails?.child_booked_times);
      }
      if (packageDetails?.child_overall_limit) {
        setChildOverAllLimit(packageDetails?.child_overall_limit);
      }
    }
  }, [packageDetails]);

  useEffect(() => {
    if (dateAvailability) {
      setTotalAdultTickets(dateAvailability?.data.adult_tickets);
      setTotalChildTickets(dateAvailability?.data.child_tickets);
    }
  }, [dateAvailability]);

  useEffect(() => {
    let flag = false;
    for (var i = 0; i < subPackages.length; i++) {
      if (subPackages[i].adult_person > 0 || subPackages[i].child_person > 0) {
        flag = true;
        showSubTitle(true);
      }
    }
    if (flag == false) {
      showSubTitle(false);
    }
  }, [subPackages]);

  useEffect(() => {
    let flag = false;
    for (var i = 0; i < meals.length; i++) {
      if (meals[i].person > 0) {
        flag = true;
        showMealTitle(true);
      }
    }
    if (flag == false) {
      showMealTitle(false);
    }
  }, [meals]);

  useEffect(() => {
    let totalAmount = 0;
    if (packageDetails?.tags?.includes("pos")) {
      totalAmount =
        addPerson * customAdultPrice +
        addChild * customChildPrice +
        packagePrice +
        mealPrice +
        transportPrice;
    } else {
      totalAmount =
        addPerson * packageDetails?.price +
        addChild * packageDetails?.child_price +
        packagePrice +
        mealPrice +
        transportPrice;
    }

    let cpTotal = totalAmount;
    if (skipAddon == "1") {
      cpTotal = cpTotal - packagePrice;
    }
    if (skipTranport == "1") {
      cpTotal = cpTotal - transportPrice;
    }
    if (skipFood == "1") {
      cpTotal = cpTotal - mealPrice;
    }

    // const newDetails = [];
    // const count = addChild + addPerson;
    // for (let i = 0; i < count; i++) {
    //   const newDetail = {
    //     name: "",
    //     dob: new Date(),
    //   };
    //   newDetails.push(newDetail);
    // }
    // setDetails(newDetails);

    console.log("skipdata", skipAddon, skipTranport, skipFood);
    console.log(
      addPerson,
      addChild,
      packagePrice,
      mealPrice,
      transportPrice,
      totalAmount
    );
    if (offType == "aed") {
      if (totalAmount - offValue > 0) {
        if (maxDiscountVal) {
          offValue = Math.min(parseInt(offValue), parseInt(maxDiscountVal));
        }
        if (minCart) {
          if (totalAmount >= minCart) {
            setTotal(totalAmount - offValue);
          } else {
            setTotal(totalAmount);
          }
        } else {
          setTotal(totalAmount - offValue);
        }
      } else {
        setTotal(0);
      }
    } else if (offType == "percentage") {
      if (totalAmount - (cpTotal * offValue) / 100 > 0) {
        let maxDiscount = ((cpTotal * offValue) / 100).toFixed(2);
        if (maxDiscountVal) {
          maxDiscount = Math.min(
            parseInt(maxDiscount),
            parseInt(maxDiscountVal)
          );
        }
        if (minCart) {
          if (totalAmount >= minCart) {
            setTotal(totalAmount - maxDiscount);
          } else {
            setTotal(totalAmount);
          }
        } else {
          setTotal(totalAmount - maxDiscount);
        }
      } else {
        setTotal(0);
      }
    } else if (offType == "buy_x_get_y") {
      let buyQty = couponBuyQty;
      let freeQty = couponGetQty;
      let tempChildQty = addChild;
      let tempChildQtyt = addChild;
      let tempAdultQty = addPerson;
      let tempAdultQtyt = addPerson;
      let buyType = couponBuy;
      let freeType = couponGet;

      if (buyType == "adult") {
        for (var i = 1; i <= tempAdultQtyt; i++) {
          if (i % buyQty == 0) {
            if (freeType == "child") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempChildQty > 0) tempChildQty = tempChildQty - 1;
              }
            } else if (freeType == "adult") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempAdultQty > i) tempAdultQty = tempAdultQty - 1;
              }
            }
          }
        }
      } else if (buyType == "child") {
        for (var i = 1; i <= tempChildQtyt; i++) {
          if (i % buyQty == 0) {
            if (freeType == "child") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempChildQty > i) tempChildQty = tempChildQty - 1;
              }
            } else if (freeType == "adult") {
              for (var j = 1; j <= freeQty; j++) {
                if (tempAdultQty > 0) tempAdultQty = tempAdultQty - 1;
              }
            }
          }
        }
      }
      var total_amount =
        tempChildQty * packageDetails?.child_price +
        tempAdultQty * packageDetails?.price;
      var couponVal =
        addPerson * adultPrice + addChild * childPrice - total_amount;
      setTotal(totalAmount - couponVal);
    } else {
      setTotal(totalAmount);
    }
  }, [
    addPerson,
    addChild,
    packagePrice,
    mealPrice,
    transportPrice,
    customAdultPrice,
    customChildPrice,
  ]);

  useEffect(() => {
    console.log(packageSlots);
    setTimeSlots([]);
    for (let i = 0; i < packageSlots?.length; i++) {
      if (packageSlots[i].adult_tickets === "") {
        var adultTicket = 9999;
      } else {
        var adultTicket = packageSlots[i].adult_tickets;
      }
      if (packageSlots[i].child_tickets === "") {
        var childTicket = 9999;
      } else {
        var childTicket = packageSlots[i].child_tickets;
      }
      console.log(adultTicket, childTicket);
      if (
        adultTicket >= addPerson &&
        childTicket >= addChild &&
        packageSlots[i].time_slot != "" &&
        packageSlots[i].enable === "1"
      ) {
        setTimeSlots((timeSlots) => [
          ...timeSlots,
          {
            value: `${packageSlots[i]?.time_slot}`,
            label: `${packageSlots[i]?.time_slot}`,
            adult_limit: `${
              packageSlots[i].adult_tickets == ""
                ? 1000
                : packageSlots[i].adult_tickets
            }`,
            child_limit: `${
              packageSlots[i]?.child_tickets == ""
                ? 1000
                : packageSlots[i]?.child_tickets
            }`,
            id: `${packageSlots[i]?.id}`,
          },
        ]);
      }
    }
  }, [packageSlots, addPerson, addChild]);

  const changeTimeSlot = (idx) => {
    let index = parseInt(idx);
    setTimeSlot(timeSlots[index].time_slot.slice(0, 7));
    setSelectedTimeSlot(index);
    setTimeSlotValue(timeSlots[index].time_slot.slice(0, 7));
  };

  const checkTransport = (item, flag) => {
    if (item == null && flag == false) {
      setTransportID(0);
      setTransportPrice(0);
      return;
    }
    setTransportName(item.value);
    setOneWay(flag);
    setTransportID(item.id);
    if (flag) {
      setTransportPrice(parseInt(item.one_way));
    } else {
      setTransportPrice(parseInt(item.two_way));
    }
  };

  const handleDateChange = (date) => {
    const d = new Date(date.value);
    console.log(date.value);
    // if (
    //   d.getDate() == "31" &&
    //   d.getMonth() == "11" &&
    //   packageDetails.destination_id == "1"
    // ) {
    //   alert(
    //     "Please call us on +971 6 802 1111 or email us on mleihamanagement@discovermleiha.ae to make your booking"
    //   );
    //   setSelectedDate(null);
    //   setRealDate("");
    //   return false;
    // }

    setLoading(true);
    setSelectedDate(date.value);
    setRealDate(moment(date.value).format("DD/MM"));
    setTimeSlot("");
    setSelectedTimeSlot(-1);
    const dateCheck = new FormData();
    dateCheck.append("action", "dateAvailabilityCheck");
    dateCheck.append("id", packageDetails?.id);
    dateCheck.append("date", moment(date.value).format("MM/DD/YYYY"));
    getDateAvailability(dateCheck);
    if (packageDetails?.enable_time_picker == "1") {
      const slotsData = new FormData();
      slotsData.append("action", "packageSlots");
      slotsData.append("id", packageDetails?.id);
      slotsData.append("date", moment(date).format("MM/DD/YYYY"));
      getPackageSlots(slotsData);
    }
  };

  const getPackageSlots = async (slotsData) => {
    setLoading(true);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      slotsData,
      false
    );
    if (data) setTimeSlots(data.data);
    setLoading(false);
  };

  const getDateAvailability = async (dateCheck) => {
    setLoading(true);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      dateCheck,
      false
    );
    if (data) setDateAvailability(data);
    setLoading(false);
  };

  const setNumPerson = (adult, child) => {
    setAddPerson(adult);
    setAddChild(child);
  };

  const addSubAdult = (id, state, item) => {
    if (state) {
      if (
        item.adult_person == 0 ||
        item.adult_price == "" ||
        parseInt(item.adult_price) == 0
      ) {
        return;
      }
      subPackages[id].adult_person = item.adult_person - 1;
    } else {
      if (item.adult_price == "" || parseInt(item.adult_price) == 0) {
        return;
      }
      subPackages[id].adult_person = item.adult_person + 1;
    }
    setSubPackages((subPackages) => [...subPackages]);
    calulatePackages();
  };

  const addSubChild = (id, state, item) => {
    if (state) {
      if (
        item.child_person == 0 ||
        item.child_price == "" ||
        parseInt(item.child_price) == 0
      ) {
        return;
      }
      subPackages[id].child_person = item.child_person - 1;
    } else {
      if (item.child_price == "" || parseInt(item.child_price) == 0) {
        return;
      }
      subPackages[id].child_person = item.child_person + 1;
    }
    setSubPackages((subPackages) => [...subPackages]);
    calulatePackages();
  };

  const calulatePackages = () => {
    let sum_person = 0;
    let sum_adult = 0;
    let sum_child = 0;
    let sum_price = 0;
    for (let i = 0; i < subPackages?.length; i++) {
      sum_adult += parseInt(subPackages[i]?.adult_person);
      sum_price +=
        parseInt(subPackages[i]?.adult_price) *
        parseInt(subPackages[i]?.adult_person);
      sum_child += parseInt(subPackages[i]?.child_person);
      if (
        subPackages[i]?.child_price != "" &&
        subPackages[i]?.child_price != null
      ) {
        sum_price +=
          parseInt(subPackages[i]?.child_price) *
          parseInt(subPackages[i]?.child_person);
      }
    }
    setSubAdultQty(sum_adult);
    setSubChildQty(sum_child);
    setPackagePrice(sum_price);
    if (sum_adult + sum_child == 0) {
      setPackageName("SELECT PACKAGE");
    } else {
      setPackageName(sum_adult + sum_child + " items, " + sum_price + " AED");
    }
  };

  const addMealPerson = (id, state, item) => {
    if (state) {
      if (item.person == 0) {
        return;
      }
      meals[id].person = item.person - 1;
    } else {
      meals[id].person = item.person + 1;
    }
    setMeals((meals) => [...meals]);
    calulateMeals();
  };

  const calulateMeals = () => {
    let sum_person = 0;
    let sum_price = 0;
    for (let i = 0; i < meals?.length; i++) {
      sum_person += parseInt(meals[i]?.person);
      sum_price += parseInt(meals[i]?.price) * parseInt(meals[i]?.person);
    }
    setMealPrice(sum_price);
    if (sum_person == 0) {
      setMealName("SELECT MEALS");
    } else {
      setMealName(sum_person + " items, " + sum_price + " AED");
    }
  };

  const nextCheckPackage = () => {
    let temp = 100 / progressCount;
    if (!isDate) {
      isDate = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_date_picker) {
        showTimeSlotSelector(true);
        nextPage();
      }
    } else if (!isTime) {
      isTime = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_time_picker) {
        showSubPackageSelector(true);
        nextPage();
      }
    } else if (!isSub) {
      isSub = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_sub_packages) {
        showMealSelector(true);
        nextPage();
      }
    } else if (!isTransport) {
      isTransport = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_transport) {
        showTransportSelector(true);
        nextPage();
      }
    } else if (!isMeal) {
      isMeal = true;
      if (parseInt(progressValue + temp) == 100) {
        showBooking(true);
      }
      if (!enable_meal) {
        showBooking(true);
        nextPage();
      }
    }
  };

  const coupon = () => {
    let subpackage_id = [];
    let subpackage_adult = [];
    let subpackage_child = [];
    for (let i = 0; i < subPackages?.length; i++) {
      if (
        subPackages[i].adult_person == 0 &&
        subPackages[i].child_person == 0
      ) {
        continue;
      }
      subpackage_id.push(subPackages[i].id);
      subpackage_adult.push(subPackages[i].adult_person);
      subpackage_child.push(subPackages[i].child_person);
    }
    let meal_id = [];
    let meal_qty = [];
    for (let i = 0; i < meals?.length; i++) {
      if (meals[i].person == 0) {
        continue;
      }
      meal_id.push(meals[i].id);
      meal_qty.push(meals[i].person);
    }

    let bookData = {
      package_id: packageDetails?.id,
      adult_qty: addPerson,
      child_qty: addChild,
    };
    if (subpackage_id.length > 0) {
      bookData = {
        ...bookData,
        subpackages_id: subpackage_id.map((id) => Number(id)),
        subpackages_adult_qty: subpackage_adult,
        subpackages_child_qty: subpackage_child,
      };
    }
    if (timeSlots) {
      bookData = {
        ...bookData,
        time_slot_id: timeSlots[selectedTimeSlot]?.id,
        time_slot_data: timeSlots[selectedTimeSlot],
      };
    }
    if (packageDetails?.enable_date_picker) {
      bookData = {
        ...bookData,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      };
    }
    if (meal_id.length > 0) {
      bookData = { ...bookData, meals_id: meal_id.map((id) => Number(id)) };
    }
    if (meal_qty.length > 0) {
      bookData = { ...bookData, meals_qty: meal_qty };
    }
    if (transports.length > 0) {
      if (transport_id != 0) {
        bookData = {
          ...bookData,
          transport_id: transport_id,
          transport_type: isOneWay,
          transport_name: transport_name,
        };
      }
    }
    bookData.total = total;
    bookData = {
      packages: [{ ...bookData }],
      coupon: couponValue ? couponValue : tempCouponValue,
    };
    setTempCouponValue(couponValue ? couponValue : tempCouponValue);
    const data = new FormData();
    data.append("action", "validateCoupon");
    data.append("items", JSON.stringify(bookData));
    data.append("user_id", "2");
    getCouponValidity(data);
  };

  const couponValidateOnOpen = async () => {
    if (!showDetails && tempCouponValue) {
      await coupon();
    }
    isShowDetails(true);
  };

  useEffect(() => {
    let totalAmount =
      addPerson * packageDetails?.price +
      addChild * packageDetails?.child_price +
      packagePrice +
      mealPrice +
      transportPrice;

    if (showDetails) {
      setCoupon(false);
      setCouponID("");
      setCouponValue("");
      setTempCouponValue("");
      setRealCoupon("");
      setOffType("");
      setSkipAddon(0);
      setSkipTransport(0);
      setSkipFood(0);
      setCouponBuy("");
      setCouponBuyQty(0);
      setCouponGet("");
      setCouponGetQty(0);
      setOffValue(0);

      setTotal(totalAmount);
      setTimeout(
        function () {
          setAlert(false);
        }.bind(this),
        2000
      );
    }
    // check if coupon response is contained current package id
    if (
      couponValidityData &&
      couponValidityData.packages &&
      couponValidityData.packages.length > 0 &&
      !couponValidityData?.packages[0].package_id ===
        packageDetails?.id?.toString()
    ) {
      setCoupon(false);
      setCouponID("");
      setCouponValue("");
      setTempCouponValue("");
      setRealCoupon("");
      setOffType("");
      setSkipAddon(0);
      setSkipTransport(0);
      setSkipFood(0);
      setCouponBuy("");
      setCouponBuyQty(0);
      setCouponGet("");
      setCouponGetQty(0);
      setOffValue(0);
      setAlert(true);
      setAlertText("Coupon not valid for this Package");
      setTotal(totalAmount);
      setTimeout(
        function () {
          setAlert(false);
        }.bind(this),
        2000
      );
    }
    // check if total => min_cart from coupon
    if (
      couponValidityData &&
      couponValidityData.min_cart &&
      total < couponValidityData.min_cart
    ) {
      setCoupon(false);
      setCouponID("");
      setCouponValue("");
      setTempCouponValue("");
      setRealCoupon("");
      setOffType("");
      setCouponBuy("");
      setCouponBuyQty(0);
      setCouponGet("");
      setCouponGetQty(0);
      setOffValue(0);
      setAlert(true);
      setTotal(totalAmount);
      const amount = parseInt(couponValidityData.min_cart) - total;
      setAlertText(`Add ${amount} Amount more to apply this coupon.`);
      setTimeout(
        function () {
          setAlert(false);
        }.bind(this),
        2000
      );
    } else if (1) {
      setCoupon(true);
      setCouponID(couponValidityData?.id);
      setOffType(couponValidityData?.type);
      setSkipAddon(couponValidityData?.skip_add_on);
      setSkipTransport(couponValidityData?.skip_transport);
      setSkipFood(couponValidityData?.skip_food);
      setCouponBuy(couponValidityData?.buy);
      setCouponBuyQty(couponValidityData?.buy_qty);
      setCouponGet(couponValidityData?.free);
      setCouponGetQty(couponValidityData?.free_qty);

      setOffValue(parseInt(couponValidityData?.off));

      setRealCoupon(couponValue ? couponValue : tempCouponValue);
      if (couponValidityData?.max_discount) {
        setMaxDiscountVal(couponValidityData.max_discount);
      }
      if (couponValidityData?.min_cart) {
        setMinCart(couponValidityData.min_cart);
      }
      let cpTotal = totalAmount;
      if (skipAddon == "1") {
        cpTotal = cpTotal - packagePrice;
      }
      if (skipTranport == "1") {
        cpTotal = cpTotal - transportPrice;
      }
      if (skipFood == "1") {
        cpTotal = cpTotal - mealPrice;
      }

      if (couponValidityData?.type == "aed") {
        console.log(couponValidityData?.off);
        console.log(couponValidityData?.max_discount);

        let maxDiscount = couponValidityData?.off;

        if (couponValidityData?.max_discount) {
          maxDiscount = Math.min(
            parseInt(couponValidityData?.off),
            parseInt(couponValidityData?.max_discount)
          );
        }

        if (totalAmount - maxDiscount > 0) {
          setTotal(totalAmount - maxDiscount);
        } else {
          setTotal(0);
        }
      } else if (couponValidityData?.type == "percentage") {
        if (totalAmount - (cpTotal * couponValidityData?.off) / 100 > 0) {
          let maxDiscount = ((cpTotal * couponValidityData?.off) / 100).toFixed(
            2
          );

          if (couponValidityData?.max_discount) {
            maxDiscount = Math.min(
              parseInt(maxDiscount),
              parseInt(couponValidityData?.max_discount)
            );
          }

          setTotal(totalAmount - maxDiscount);
        } else {
          setTotal(0);
        }
      } else if (couponValidityData?.type == "buy_x_get_y") {
        let buyQty = couponValidityData?.buy_qty;
        let freeQty = couponValidityData?.free_qty;

        let tempChildQty = addChild;
        let tempChildQtyt = addChild;
        let tempAdultQty = addPerson;
        let tempAdultQtyt = addPerson;

        let buyType = couponValidityData?.buy;
        let freeType = couponValidityData?.free;

        if (buyType == "adult") {
          for (var i = 1; i <= tempAdultQtyt; i++) {
            if (i % buyQty == 0) {
              if (freeType == "child") {
                for (var j = 1; j <= freeQty; j++) {
                  if (tempChildQty > 0) tempChildQty = tempChildQty - 1;
                }
              } else if (freeType == "adult") {
                for (var j = 1; j <= freeQty; j++) {
                  if (tempAdultQty > i) tempAdultQty = tempAdultQty - 1;
                }
              }
            }
          }
        } else if (buyType == "child") {
          for (var i = 1; i <= tempChildQtyt; i++) {
            if (i % buyQty == 0) {
              if (freeType == "child") {
                for (var j = 1; j <= freeQty; j++) {
                  if (tempChildQty > i) tempChildQty = tempChildQty - 1;
                }
              } else if (freeType == "adult") {
                for (var j = 1; j <= freeQty; j++) {
                  if (tempAdultQty > 0) tempAdultQty = tempAdultQty - 1;
                }
              }
            }
          }
        }
        var total_amount =
          tempChildQty * packageDetails?.child_price +
          tempAdultQty * packageDetails?.price;
        var couponVal =
          addPerson * adultPrice + addChild * childPrice - total_amount;
        setTotal(totalAmount - couponVal);
      } else {
        setTotal(totalAmount);
      }
      console.log(couponValidityData);
      setWobble(1);
      isShowCoupon(false);
      if (couponValidityData?.packages) {
        if (!couponValidityData?.packages[0]?.deducted_value) {
          setTempCouponValue("");
        }
      } else {
        setTempCouponValue("");
      }
      setCouponValue("");
      //   getCouponValidity("");
    }
  }, [couponValidityData]);

  const getCouponValidity = async (formData) => {
    setLoading(true);
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) setDateAvailability(data);
    setLoading(false);
  };

  const bookYourPackage = async () => {
    setLoading(true);
    let subpackage_id = [];
    let subpackage_adult = [];
    let subpackage_child = [];
    for (let i = 0; i < subPackages?.length; i++) {
      if (
        subPackages[i].adult_person == 0 &&
        subPackages[i].child_person == 0
      ) {
        continue;
      }
      subpackage_id.push(subPackages[i].id);
      subpackage_adult.push(subPackages[i].adult_person);
      subpackage_child.push(subPackages[i].child_person);
    }
    let meal_id = [];
    let meal_qty = [];
    for (let i = 0; i < meals?.length; i++) {
      if (meals[i].person == 0) {
        continue;
      }
      meal_id.push(meals[i].id);
      meal_qty.push(meals[i].person);
    }
    var customPrice = {};
    if (packageDetails?.tags?.includes("pos")) {
      customPrice = {
        adult_price: customAdultPrice,
        child_price: customChildPrice,
      };
    }

    let bookData = {
      ...customPrice,
      package_id: packageDetails?.id,
      adult_qty: addPerson,
      child_qty: addChild,
    };
    if (subpackage_id.length > 0) {
      bookData = {
        ...bookData,
        subpackages_id: subpackage_id.map((id) => Number(id)),
        subpackages_adult_qty: subpackage_adult,
        subpackages_child_qty: subpackage_child,
      };
    }
    if (timeSlots) {
      bookData = {
        ...bookData,
        time_slot_id: timeSlots[selectedTimeSlot]?.id,
        time_slot_data: timeSlots[selectedTimeSlot],
      };
    }
    if (packageDetails?.enable_date_picker) {
      bookData = {
        ...bookData,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      };
    }
    if (meal_id.length > 0) {
      bookData = { ...bookData, meals_id: meal_id.map((id) => Number(id)) };
    }
    if (meal_qty.length > 0) {
      bookData = { ...bookData, meals_qty: meal_qty };
    }
    if (transports.length > 0) {
      if (transport_id != 0) {
        bookData = {
          ...bookData,
          transport_id: transport_id,
          transport_type: isOneWay,
          transport_name: transport_name,
        };
      }
    }
    if (shipping) bookData = { ...bookData, shipping_id: "1" };
    bookData.total =
      ((summerCamp
        ? selectedSummerGroup
          ? selectedSummerGroup.price
          : selectedSummerDate.reduce((sum, value) => {
              return sum + value.price;
            }, 0)
        : 0) +
        total) *
      (addChild + addPerson);
    bookData = {
      packages: [{ ...bookData }],
    };
    if (summerCamp) {
      bookData = {
        packages: [
          {
            ...bookData.packages[0],
            summercamps: [
              {
                qty: "2",
                dates: selectedSummerDate.map((item) => item.date),
              },
            ],
          },
        ],
      };
    }
    if (tempCouponValue) {
      bookData = { ...bookData, coupon: tempCouponValue };
    }
    console.log(attendeesList);
    if (collectDetails) {
      bookData = {
        packages: [
          {
            ...bookData.packages[0],
            attendees: attendeesList
              .filter((att) => att.checked)
              .map((at) => at.id),
          },
        ],
      };
    }
    bookData = {
      ...bookData,
      site: window.location.host,
      // redirect_to: "https://discovershurooq.ae/order-history",
    };
    console.log(bookData);
    const bookFormData = new FormData();
    bookFormData.append("action", "createPurchase");
    bookFormData.append("items", JSON.stringify(bookData));
    bookFormData.append("site", "internalportal.houseofwisdom.ae");
    bookFormData.append("channel", "pos-houseofwisdom");
    // bookFormData.append(
    //   "redirect_to",
    //   "https://discovershurooq.ae/order-history"
    // );
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      bookFormData,
      false
    );
    if (data) {
      setOpenPaymentModal(true);
      setPurchaseData(data);
    }
    setLoading(false);
  };

  const initiatePayment = async (method) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "initiatePayment");
    formData.append("payment_method", method);
    formData.append("purchase_number", purchaseData.purchase_number);
    formData.append("purchase_id", purchaseData.id);
    formData.append(
      "redirect_to",
      `${window.location.origin}/profile?purchases`
    );
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) window.location.href = data.payment_link;
    else setLoading(false);
  };

  useEffect(() => {
    if (step === 1 && collectDetails) retrieveAttendees();
  }, [step]);

  const retrieveAttendees = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "get_attendees");
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data)
      setAttendessList(
        data.data.map((dt) => {
          return { ...dt, checked: false };
        })
      );
    setOpenModal(false);
    setDetails({
      name: "",
      dob: "",
    });
    setLoading(false);
  };

  const addAttendees = async () => {
    if (!details.name) {
      alert(language.enter_name[locale]);
      return;
    }
    if (!details.dob) {
      alert(language.dob[locale]);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "add_attendees");
    formData.append("name", details.name);
    formData.append("dob", moment(details.dob).format("YYYY-MM-DD"));
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) retrieveAttendees();
    setLoading(false);
  };

  const nextPage = () => {
    let err = "";
    if (step === 0) {
      if (packageDetails.adult_min_qty || packageDetails.child_min_qty) {
        if (addPerson < packageDetails.adult_min_qty) {
          err += `${language.adult_min[locale]} ${packageDetails.adult_min_qty}`;
        }
        if (
          packageDetails.adult_max_qty &&
          addPerson > packageDetails.adult_max_qty
        ) {
          err += `${language.adult_max[locale]} ${packageDetails.adult_max_qty}`;
        }
        if (addChild < packageDetails.child_min_qty) {
          err += `\n${language.child_min[locale]} ${packageDetails.child_min_qty}`;
        }
        if (
          packageDetails.child_max_qty &&
          addChild > packageDetails.child_max_qty
        ) {
          err += `\n${language.child_max[locale]} ${packageDetails.child_max_qty}`;
        }

        if (err != "") {
          alert(err);
          return false;
        }
      }

      if (packageDetails.overall_min_limit) {
        let total_person = addPerson + addChild;
        if (total_person < packageDetails.overall_min_limit) {
          alert(`Select Minimum ${packageDetails.overall_min_limit} Person`);
          return false;
        }
      }

      if (addPerson == 0 && addChild == 0) {
        alert(language.min_passangers[locale]);
        return false;
      }
    }

    if (step === 1 && collectDetails) {
      const count = addChild + addPerson;
      const checkedAttendees = attendeesList.filter(
        (attendee) => attendee.checked
      );

      if (checkedAttendees.length !== count) {
        setAttendeeAlert(
          `${language.Kindly_select[locale]} ${count} ${language.attendees[locale]}`
        );
        return false;
      }
    }

    if (step === 2) {
      if (enable_date_picker && !isTimeSlotSelector) {
        if (realDate == "Invalid date") {
          alert(language.select_date[locale]);
          return false;
        }
        if (
          total_adult_tickets >= addPerson &&
          total_child_tickets >= addChild
        ) {
          let temp = 100 / progressCount;
          setProgressValue(progressValue + temp);
          if (isTransportSelector) {
            showMealSelector(true);
            nextCheckPackage();
          } else if (isSubPackageSelector) {
            showTransportSelector(true);
            nextCheckPackage();
          } else if (isTimeSlotSelector) {
            showSubPackageSelector(true);
            nextCheckPackage();
          } else if (isDateSelector) {
            // showTimeSlotSelector(true);
            nextCheckPackage();
          } else {
            // showDateSelector(true);
            nextCheckPackage();
          }
          return true;
        } else {
          alert("Selected Date is Fully Booked");
          return false;
        }
      }
    }
    if (step === 3 && summerCamp) {
      if (!selectedSummerDate.length) {
        alert(language.select_date[locale]);
        return false;
      }
    }
    if (step === 4) {
      if (enable_time_picker) {
        if (time_slot == "") {
          alert(language.select_time[locale]);
          return false;
        }
        if (timeSlots?.length < 0) {
          alert(language.time_fully_booked[locale]);
          return false;
        }
      }
      // if (isLoading) {
      //   return false;
      // }
      // let temp = 100 / progressCount;
      // setProgressValue(progressValue + temp);
      // if (isMealSelector) {
      //   showMealSelector(false);
      //   showSubPackageSelector(false);
      //   showTimeSlotSelector(false);
      //   showDateSelector(false);
      //   showTransportSelector(false);
      // } else if (isTransportSelector) {
      //   showMealSelector(true);
      //   nextCheckPackage();
      // } else if (isSubPackageSelector) {
      //   showTransportSelector(true);
      //   nextCheckPackage();
      // } else if (isTimeSlotSelector) {
      //   showSubPackageSelector(true);
      //   nextCheckPackage();
      // } else if (isDateSelector) {
      //   showTimeSlotSelector(true);
      //   nextCheckPackage();
      // } else {
      //   // showDateSelector(true);
      //   nextCheckPackage();
      // }
    }
    return true;
  };

  const nextStep = () => {
    if (nextPage()) {
      let nextStep;
      if (
        step === 0 &&
        !collectDetails &&
        !enable_date_picker &&
        !enable_time_picker &&
        !summerCamp
      ) {
        nextStep = 5;
      } else if (step === 0 && !collectDetails) {
        nextStep = 2;
      } else if (step === 1 && !enable_date_picker) {
        nextStep = 3;
      } else if (step === 2 && !summerCamp && enable_time_picker) {
        nextStep = 4;
      } else if ((step === 3 || step === 2) && !enable_time_picker) {
        nextStep = 5;
      } else {
        nextStep = step + 1;
      }
      setStep(nextStep);
    }
  };

  const prevStep = () => {
    let previousStep;
    if (
      step === 5 &&
      !collectDetails &&
      !enable_date_picker &&
      !enable_time_picker &&
      !summerCamp
    ) {
      previousStep = 0;
    } else if (step === 5 && !enable_time_picker && !enable_date_picker) {
      previousStep = 3;
    } else if ((step === 5 || step === 4) && !summerCamp) {
      previousStep = 2;
    } else if (step === 3 && !enable_date_picker) {
      previousStep = 1;
    } else if (step === 2 && enable_date_picker) {
      previousStep = 0;
    } else {
      previousStep = step - 1;
    }
    setStep(previousStep);
  };

  // function gotoSlide(slide_name) {
  //   if (addPerson == 0 && addChild == 0) {
  //     alert("Select Passengers - Minimum 1");
  //     return false;
  //   }
  //   switch (slide_name) {
  //     case "user":
  //       isDate = false;
  //       isTime = false;
  //       isTransport = false;
  //       isSub = false;
  //       isMeal = false;
  //       showMealSelector(false);
  //       showSubPackageSelector(false);
  //       showTimeSlotSelector(false);
  //       showDateSelector(false);
  //       showTransportSelector(false);
  //       return true;
  //     case "date":
  //       isDate = true;
  //       isTime = false;
  //       isTransport = false;
  //       isSub = false;
  //       isMeal = false;
  //       showMealSelector(false);
  //       showSubPackageSelector(false);
  //       showTimeSlotSelector(false);
  //       showTransportSelector(false);
  //       showDateSelector(true);
  //       return true;
  //     case "time":
  //       if (realDate == "" && enable_date_picker) {
  //         alert("Please Select a Date");
  //         return false;
  //       }
  //       if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
  //         alert("Selected Date is Fully Booked");
  //         return false;
  //       }
  //       isDate = true;
  //       isTime = true;
  //       isTransport = false;
  //       isSub = false;
  //       isMeal = false;
  //       showMealSelector(false);
  //       showSubPackageSelector(false);
  //       showTimeSlotSelector(true);
  //       showTransportSelector(false);
  //       // showDateSelector(true);
  //       return true;
  //     case "sub_packages":
  //       if (realDate == "" && enable_date_picker) {
  //         alert("Please Select a Date");
  //         return false;
  //       }
  //       if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
  //         alert("Selected Time is Fully Booked");
  //         return false;
  //       }
  //       if (time_slot == "" && enable_time_picker) {
  //         return false;
  //       }
  //       isDate = true;
  //       isTime = true;
  //       isSub = true;
  //       isTransport = false;
  //       isMeal = false;
  //       showTransportSelector(false);
  //       showMealSelector(false);
  //       showSubPackageSelector(true);
  //       showTimeSlotSelector(true);
  //       showDateSelector(true);
  //       return true;
  //     case "transports":
  //       if (realDate == "" && enable_date_picker) {
  //         alert("Please Select a Date");
  //         return false;
  //       }
  //       if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
  //         alert("Selected Date is Fully Booked");
  //         return false;
  //       }
  //       if (time_slot == "" && enable_time_picker) {
  //         return false;
  //       }
  //       isDate = true;
  //       isTime = true;
  //       isSub = true;
  //       isTransport = true;
  //       isMeal = false;
  //       showMealSelector(false);
  //       showSubPackageSelector(true);
  //       showTimeSlotSelector(true);
  //       showTransportSelector(true);
  //       showDateSelector(true);
  //       return true;
  //     case "meals":
  //       if (realDate == "" && enable_date_picker) {
  //         alert("Please Select a Date");
  //         return false;
  //       }
  //       if (total_adult_tickets < addPerson || total_child_tickets < addChild) {
  //         alert("Selected Date is Fully Booked");
  //         return false;
  //       }
  //       if (time_slot == "" && enable_time_picker) {
  //         return false;
  //       }
  //       isDate = true;
  //       isTime = true;
  //       isSub = true;
  //       isTransport = true;
  //       isMeal = true;
  //       showMealSelector(true);
  //       showSubPackageSelector(true);
  //       showTimeSlotSelector(true);
  //       showTransportSelector(true);
  //       showDateSelector(true);
  //       return true;
  //   }
  // }

  const Continue = () => (
    <button
      className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative "
      onClick={() => {
        if (step === 5) {
          if (
            (adult_overall_limit == "" ||
              parseInt(adult_overall_limit) >=
                parseInt(adult_booked_times) + addPerson) &&
            (child_overall_limit == "" ||
              parseInt(child_overall_limit) >=
                parseInt(child_booked_times) + addChild)
          ) {
            bookYourPackage();
          }
        } else {
          nextStep();
        }
      }}
      disabled={
        isLoading ||
        packageDetails?.disabled === "1" ||
        packageDetails?.enable === "0"
      }
    >
      {isLoading && (
        <div
          role="status"
          className="flex justify-center w-full h-full items-center"
        >
          <svg
            aria-hidden="true"
            class="mr-2 mt-[2px] w-5 h-5 text-[#616161] animate-spin fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      )}
      {step === 5
        ? (adult_overall_limit == "" ||
            parseInt(adult_overall_limit) >=
              parseInt(adult_booked_times) + addPerson) &&
          (child_overall_limit == "" ||
            parseInt(child_overall_limit) >=
              parseInt(child_booked_times) + addChild)
          ? language.pay[locale]
          : language.no_avaiable_qty[locale]
        : language.continue[locale]}
    </button>
  );

  const Back = () => (
    <button
      className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] rounded-[24px] w-fit border-[1.5px] border-[#FFFFFF33]"
      onClick={prevStep}
    >
      {language.back[locale]}
    </button>
  );

  return (
    <>
      {isOpen && (
        <div
          className={`flex justify-center ${
            step === -1 ? "items-start" : "items-center"
          } overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm`}
        >
          <div className="relative w-full h-full md:min-w-[400px] md:min-h-[400px] md:w-auto md:h-auto md:my-6 mx-auto max-w-[1110px]">
            <div
              className={`bg-modalBg md:max-h-[90vh] h-full min-h-screen md:min-h-[400px] px-[24px] md:px-[60px] md:py-[40px] border-0 md:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none overflow-scroll`}
            >
              {step !== -1 && (
                <div className="fixed top-0 left-[24px] right-[24px] z-10 bg-modalBg md:max-h-[90vh] pt-[20px] pb-[10px] md:static md:pt-0 md:pb-0 flex items-center justify-between mb-[60px] md:mb-[36px]">
                  <h3 className="text-[20px] md:text-[32px] text-white">
                    {step > 4
                      ? step > 3
                        ? ""
                        : language.summary[locale]
                      : step < 0
                      ? ""
                      : language.event_signup[locale]}
                  </h3>
                  {step < 5 && (
                    <button
                      className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
                      onClick={resetCloseModal}
                    >
                      <img
                        src={close}
                        alt="close"
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                      />
                    </button>
                  )}
                </div>
              )}

              {step === -1 && (
                <EventDetails
                  nextStep={nextStep}
                  closeModal={resetCloseModal}
                  packageDetails={packageDetails}
                />
              )}
              {step > -1 && step < 5 && <FormSteps step={step} data={data} />}

              {step === 0 && (
                <>
                  <Users
                    addChild={addChild}
                    setAddChild={setAddChild}
                    addPerson={addPerson}
                    setAddPerson={setAddPerson}
                    adult_price={packageDetails?.price}
                    child_price={packageDetails?.child_price}
                    setNumPerson={setNumPerson}
                    setCustomChildValu={setCustomChildValue}
                    packageDetails_id={packageDetails?.id}
                    tags={packageDetails?.tags}
                    customChildPrice={customChildPrice}
                    customAdultPrice={customAdultPrice}
                    setCustomChildPrice={setCustomChildPrice}
                    setCustomAdultPrice={setCustomAdultPrice}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 1 && collectDetails && (
                <>
                  <Details
                    details={details}
                    setDetails={setDetails}
                    attendeesList={attendeesList}
                    setAttendessList={setAttendessList}
                    addAttendees={addAttendees}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    attendeeAlert={attendeeAlert}
                    setAttendeeAlert={setAttendeeAlert}
                    isLoading={isLoading}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 2 && enable_date_picker && (
                <>
                  <Calendar
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    disabled_dates={disabled_dates}
                    disabled_weeks={disabled_weeks}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 3 && summerCamp && (
                <>
                  <SummerDate
                    summerDates={summerDates}
                    summerGroup={summerGroup}
                    selectedSummerDate={selectedSummerDate}
                    setSelectedSummerDate={setSelectedSummerDate}
                    selectedSummerGroup={selectedSummerGroup}
                    setSelectedSummerGroup={setSelectedSummerGroup}
                    count={addChild + addPerson}
                    showDates={showDates}
                    setShowDates={setShowDates}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 4 && enable_time_picker && (
                <>
                  <TimeSlot
                    selectedTimeSlot={selectedTimeSlot}
                    timeSlots={timeSlots}
                    changeTimeSlot={changeTimeSlot}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 5 && (
                <>
                  <Summary
                    addChild={addChild}
                    addPerson={addPerson}
                    adult_price={
                      packageDetails?.tags?.includes("pos")
                        ? customAdultPrice
                        : packageDetails?.price
                    }
                    child_price={
                      packageDetails?.tags?.includes("pos")
                        ? customChildPrice
                        : packageDetails?.child_price
                    }
                    selectedDate={selectedDate}
                    selectedTimeSlot={selectedTimeSlot}
                    timeSlots={timeSlots}
                    packageName={packageDetails?.package_name}
                    shipping_req={packageDetails?.shipping_required}
                    total={total}
                    enable_date_picker={enable_date_picker}
                    enable_time_picker={enable_time_picker}
                    shipping={shipping}
                    setShipping={setShipping}
                    selectedSummerDate={selectedSummerDate}
                    selectedSummerGroup={selectedSummerGroup}
                    summerCamp={summerCamp}
                    count={addChild + addPerson}
                  />
                  <div className="flex justify-center items-center w-full mt-[36px] gap-[8px] z-10 ">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {/* {step === 3 && (
                <SubPackages
                  addSubAdult={addSubAdult}
                  addSubChild={addSubChild}
                  subPackages={subPackages}
                />
              )}
              {step === 4 && (
                <FluentFood meals={meals} addMealPerson={addMealPerson} />
              )}
              {step === 5 && (
                <Transport
                  transports={transports}
                  transport_id={transport_id}
                  checkTransport={checkTransport}
                  isOneWay={isOneWay}
                  setTransportID={setTransportID}
                />
              )} */}
            </div>
          </div>
          <PaymentModal
            openPaymentModal={openPaymentModal}
            setOpenPaymentModal={setOpenPaymentModal}
            initiatePayment={initiatePayment}
          />
        </div>
      )}
    </>
  );
};

export default Modal;
