import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storeData } from "./redux/storeData";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));

const store = createStore(
  storeData,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

Bugsnag.start({
  apiKey: "bf26d26ab201d9dbce7394af1a400813",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={process.env.REACT_APP_DOMAIN_NAME}
        clientId={process.env.REACT_APP_CLIENT_ID}
        redirectUri={`${window.location.origin}/loginRedirect`}
        audience={`https://${process.env.REACT_APP_AUTH0_AUDIENCE}/api/v2/`}
        scope="openid profile email read:current_user"
        cacheLocation="localstorage"
        useRefreshTokens
      >
        <ErrorBoundary>
          <ToastContainer />
          <App />
        </ErrorBoundary>
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
