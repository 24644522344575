import React, { useEffect, useRef, useState } from "react";
import Banner from "../components/Banner";
import Events from "../components/Events";
import animate from "../gsap/animate";
import { useSelector } from "react-redux";
import { fetchClient } from "../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

const RamadanOffer = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const ref = useRef([]);
  const [loading, setLoading] = useState(false);
  const [packages, setPackages] = useState([]);
  const { heading, description, image } = useSelector(
    (state) => state.data.bookclub
  );
  const locale = useStorage();

  useEffect(() => {
    animate(ref);
    const getPackages = async () => {
      setLoading(true);
      const formData = new FormData();
      formData.append("action", "packagesByTags");
      formData.append("tag", "bookclub");
      const data = await fetchClient(
        getAccessTokenSilently,
        isAuthenticated,
        "",
        formData,
        false
      );
      if (data) setPackages(data.data);
      setLoading(false);
    };
    getPackages();
  }, []);

  return (
    <>
      <Banner
        url={process.env.REACT_APP_CMS + image.data.attributes.url}
        title="Exclusive Ramadaniyat Offer!"
        desc="%10 Discount on House of Wisdom Memberships"
      />
      <div className="w-full flex justify-center">
        <div className="sm:w-8/12 md:w-5/12 w-11/12 max-w-[2000px]">
          <p className="text-white text-[18px] my-[4rem] text-center">
            This offer is valid for both the standard and premium memberships
            throughout Ramadaniyat event period only. Students can avail the
            offer in addition to their own special discount!
          </p>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Link
          to="/membership"
          className="text-white text-[16px] px-[20px] py-[6px] gradient-border relative mt-[1rem]"
        >
          Get it Now!
        </Link>
      </div>
      <Events
        title={language.events[locale]}
        packages={packages}
        ref={(el) => (ref.current[0] = el)}
        loading={loading}
      />
    </>
  );
};

export default RamadanOffer;
