import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "../Modal/DatePicker";
import GuestCount from "../Modal/GuestCount";
import SelectPod from "./SelectPod";
import Summary from "../Modal/Summary";
import TimeSlot from "./TimeSlot";
import moment from "moment";
import close from "../../assets/svg/close.svg";
import FormSteps from "../Modal/FormSteps";
import { ReactComponent as Guests } from "../../assets/svg/reserve_guests.svg";
import { ReactComponent as Dates } from "../../assets/svg/reserve_date.svg";
import { ReactComponent as Time } from "../../assets/svg/reserve_time.svg";
import { ReactComponent as Pods } from "../../assets/svg/reserve_select.svg";
import axios from "axios";
import { fetchClient } from "../../axios-config";
import { useAuth0 } from "@auth0/auth0-react";
import { notifyInfo } from "../../utils/notifyToast";
import PaymentModal from "../PaymentModal";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const Modal = ({ isOpen, closeModal }) => {
  const { loginWithRedirect, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
  const [step, setStep] = useState(0);
  const [count, setCount] = useState(1);
  const [notes, setNotes] = useState("");
  const [radioSelect, setRadioSelect] = useState("internal");
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [duration, setDuration] = useState(2);
  const [timeBlock, setTimeBlock] = useState();
  const [selectedTimeBlock, setSelectedTimeBlock] = useState([]);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [purchaseData, setPurchaseData] = useState();
  const systemHours = moment().add(15, "minutes").hours().toString();
  const systemMinutes = moment().add(15, "minutes").minutes().toString();
  const [time, setTime] = useState({
    startTime: (systemHours.length === 1 ? "0" : "") + systemHours,
    endTime: (systemMinutes.length === 1 ? "0" : "") + systemMinutes,
  });
  const [selectedPod, setSelectedPod] = useState();
  const [pods, setPods] = useState([]);
  const [ap, setAp] = useState(
    moment().add(15, "minutes").hours() >= 12 ? "PM" : "AM"
  );
  const locale = useStorage();

  const data = [
    <Guests fill={step === 0 ? "#111" : "white"} />,
    <Dates fill={step === 1 ? "#111" : "white"} />,
    <Time fill={step === 2 ? "#111" : "white"} />,
    <Pods fill={step === 3 ? "#111" : "white"} />,
  ];

  useEffect(() => {
    var convertStartTime = timeStringToFloat(
      moment(
        time.startTime +
          ":" +
          (Math.round(time.endTime / 10) * 10 >= 60
            ? 59
            : Math.round(time.endTime / 10) * 10) +
          " " +
          ap,
        ["h:mm A"]
      ).format("HH:mm")
    );
    var times = [];
    var availableTime = [];
    var interval = duration * 60;
    var startTime =
      (convertStartTime < Number(systemHours) &&
      moment(selectedDate).format("YYYYMMDD") ===
        moment(new Date()).format("YYYYMMDD")
        ? Number(systemHours)
        : convertStartTime < 7 || convertStartTime > 23
        ? 7
        : convertStartTime) * 60;
    var endTime = 23 * 60;
    var apValues = ["AM", "PM"];

    for (var i = 0; startTime <= endTime; i++) {
      var hh = Math.floor(startTime / 60);
      var mm = Math.round(startTime % 60);
      times[i] =
        ("0" + (!hh ? 12 : hh == 12 ? 12 : hh % 12)).slice(-2) +
        ":" +
        ("0" + mm).slice(-2) +
        " " +
        apValues[Math.floor(hh / 12)];
      startTime = startTime + interval;
    }

    for (var j = 0; j <= times.length; j++) {
      if (times[j + 1]) {
        availableTime.push({
          startTime: times[j],
          endTime: times[j + 1],
        });
      }
    }
    setTimeBlock();
    setSelectedTimeBlock(availableTime);
  }, [duration, ap, time, selectedDate]);

  const timeStringToFloat = (time) => {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  };

  const formatDatetoApiValue = (time) => {
    return (
      moment(selectedDate).format("YYYY-MM-DD") +
      " " +
      moment(time.split(" ")[0] + ":" + 0 + " " + time.split(" ")[1], [
        "h:mm A",
      ]).format("HH:mm")
    );
  };

  const nextStep = () => {
    if (step === 2) {
      if (!selectedTimeBlock[timeBlock]) {
        notifyInfo(language.select_time_slot[locale]);
        return;
      }
    }
    if (step === 3) {
      if (!selectedPod) {
        notifyInfo(language.select_pod[locale]);
        return;
      }
    }
    setStep((prevState) => prevState + 1);
  };

  const prevStep = () => {
    setStep((prevState) => prevState - 1);
  };

  useEffect(() => {
    if (timeBlock?.toString()) {
      const getPods = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("action", "availableSpaces");
        formData.append("total_attendees", count);
        formData.append(
          "start_date_timing",
          formatDatetoApiValue(selectedTimeBlock[timeBlock].startTime)
        );
        formData.append(
          "end_date_timing",
          formatDatetoApiValue(selectedTimeBlock[timeBlock].endTime)
        );
        const data = await fetchClient(
          getAccessTokenSilently,
          isAuthenticated,
          "",
          formData,
          false
        );
        if (data) setPods(data.data);
        setLoading(false);
      };
      getPods();
    }
  }, [timeBlock]);

  const createPurchase = async () => {
    // if (!notes) {
    //   notifyInfo(language.no_notes[locale]);
    //   return;
    // }
    setLoading(true);
    const formData = new FormData();
    const purchaseItem = {
      spaces: [
        {
          number_of_attendees: count,
          start_date_timing: formatDatetoApiValue(
            selectedTimeBlock[timeBlock].startTime
          ),
          end_date_timing: formatDatetoApiValue(
            selectedTimeBlock[timeBlock].endTime
          ),
          space_id: selectedPod.id,
          notes: notes + (radioSelect === "internal" ? radioSelect : ""),
        },
      ],
    };
    if (radioSelect === "internal") {
      purchaseItem.spaces[0].space_hourly_cost = "0";
    }
    console.log(purchaseItem);
    formData.append("action", "createPurchase");
    formData.append("items", JSON.stringify(purchaseItem));
    formData.append("site", "internalportal.houseofwisdom.ae");
    formData.append("channel", "pos-houseofwisdom");
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) {
      setOpenPaymentModal(true);
      setPurchaseData(data);
    }
    setLoading(false);
  };

  const initiatePayment = async (method) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("action", "initiatePayment");
    formData.append("payment_method", method);
    formData.append("notes", notes);
    formData.append("purchase_number", purchaseData.purchase_number);
    formData.append("purchase_id", purchaseData.id);
    formData.append(
      "redirect_to",
      `${window.location.origin}/profile?purchases`
    );
    const data = await fetchClient(
      getAccessTokenSilently,
      isAuthenticated,
      "",
      formData,
      false
    );
    if (data) window.location.href = data.payment_link;
    else setLoading(false);
  };

  const resetReserveModal = () => {
    setStep(0);
    setCount(0);
    setLoading(false);
    setSelectedDate(new Date());
    setDuration(2);
    setTimeBlock();
    setSelectedTimeBlock([]);
    setTime({
      startTime: (systemHours.length === 1 ? "0" : "") + systemHours,
      endTime: (systemMinutes.length === 1 ? "0" : "") + systemMinutes,
    });
    setSelectedPod();
    setPods([]);
    setAp(moment().add(15, "minutes").hours() >= 12 ? "PM" : "AM");
    setNotes("");
    setRadioSelect("internal");
    setOpenPaymentModal(false);
    setPurchaseData();
    closeModal();
  };

  const Back = () => (
    <button
      className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] rounded-[24px] w-fit border-[1.5px] border-[#FFFFFF33]"
      onClick={prevStep}
      disabled={loading}
    >
      {language.back[locale]}
    </button>
  );

  const Continue = () => (
    <button
      className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative "
      onClick={
        step === 4
          ? createPurchase
          : isAuthenticated
          ? nextStep
          : loginWithRedirect
      }
      disabled={loading}
    >
      {loading && (
        <div
          role="status"
          className="flex justify-center w-full h-full items-center"
        >
          <svg
            aria-hidden="true"
            class="mr-2 mt-[2px] w-5 h-5 text-[#616161] animate-spin fill-white"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span class="sr-only">Loading...</span>
        </div>
      )}
      {step === 4
        ? language.pay[locale]
        : isAuthenticated
        ? language.continue[locale]
        : language.login[locale]}
    </button>
  );

  return (
    <>
      {isOpen && (
        <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
          <div className="relative w-full h-full md:min-w-[400px] md:min-h-[400px] md:w-auto md:h-auto md:my-6 mx-auto max-w-3xl">
            <div className="bg-modalBg md:max-h-[90vh] h-full min-h-screen md:min-h-[400px] px-[24px] py-[20px] md:px-[60px] md:py-[40px] border-0 md:rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none overflow-scroll">
              <div className="fixed top-0 left-[24px] right-[24px] z-10 bg-modalBg md:max-h-[90vh] pt-[20px] pb-[10px] md:static md:pt-0 md:pb-0 flex items-center justify-between mb-[60px] md:mb-[36px] rounded-t ">
                <h3 className="text-[20px] md:text-[32px] text-white">
                  {step > 3
                    ? step > 4
                      ? ""
                      : language.summary[locale]
                    : language.reserve_pod[locale]}
                </h3>
                {step < 5 && (
                  <button
                    className="relative opacity-7 h-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
                    onClick={resetReserveModal}
                  >
                    <img
                      src={close}
                      alt="close"
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    />
                  </button>
                )}
              </div>
              {step < 4 && <FormSteps step={step} data={data} />}
              {step === 0 && (
                <>
                  <GuestCount count={count} setCount={setCount} />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10">
                    <Continue />
                  </div>
                </>
              )}
              {step === 1 && (
                <>
                  <DatePicker
                    selectedDate={selectedDate}
                    themeVariant="dark"
                    setSelectedDate={setSelectedDate}
                  />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10 mt-[36px] gap-[8px]">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 2 && (
                <>
                  <TimeSlot
                    duration={duration}
                    setDuration={setDuration}
                    timeBlock={timeBlock}
                    setTimeBlock={setTimeBlock}
                    selectedTimeBlock={selectedTimeBlock}
                    ap={ap}
                    setAp={setAp}
                    time={time}
                    setTime={setTime}
                  />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10 mt-[36px] gap-[8px]">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 3 && (
                <>
                  <SelectPod
                    loading={loading}
                    pods={pods}
                    selectedPod={selectedPod}
                    setSelectedPod={setSelectedPod}
                  />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10 mt-[36px] gap-[8px]">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
              {step === 4 && (
                <>
                  <Summary
                    selectedTimeBlock={selectedTimeBlock[timeBlock]}
                    selectedPod={selectedPod}
                    selectedDate={selectedDate}
                    count={count}
                    notes={notes}
                    setNotes={setNotes}
                    radioSelect={radioSelect}
                    setRadioSelect={setRadioSelect}
                  />
                  <div className="fixed bottom-0 left-0 z-10 bg-modalBg md:max-h-[90vh] pt-[10px] pb-[20px] md:static md:pt-0 md:pb-0 flex justify-center items-center w-full z-10 mt-[36px] gap-[8px]">
                    <Back />
                    <Continue />
                  </div>
                </>
              )}
            </div>
          </div>
          <PaymentModal
            openPaymentModal={openPaymentModal}
            setOpenPaymentModal={setOpenPaymentModal}
            initiatePayment={initiatePayment}
          />
        </div>
      )}
    </>
  );
};

export default Modal;
