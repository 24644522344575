import React, { useEffect, useState, useRef } from "react";
import Banner from "../components/Banner";
import ReserveCard from "../components/ReserveCard";
import pods from "../assets/svg/Book-Pod.svg";
import books from "../assets/svg/reserve-book.svg";
import lab from "../assets/svg/al-jazri-lab.svg";
import { useSelector } from "react-redux";
import bookevent from "../assets/svg/book-event.svg";
import expresso from "../assets/svg/BooEspressoMachine_white.svg";
import tour from "../assets/svg/icn_tour.svg";
import space from "../assets/svg/icn_popupeventspace.svg";
import animate from "../gsap/animate";
import close from "../assets/svg/close.svg";
import ReservePods from "../components/ReservePods/ReservePods";
import ContactModal from "../components/ContactModal";
import language from "../Json/locale.json";
import useStorage from "../hooks/useStorage";

const images = [pods, books, lab, lab, bookevent, expresso, tour, books, space];

const Reserve = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openContactForm, setOpenContactForm] = useState(false);
  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const ref = useRef([]);
  const { heading, description, image } = useSelector(
    (state) => state.data.reserve
  );
  const locale = useStorage();

  useEffect(() => {
    setData(
      language.reserve_pods[locale].map((pods, idx) => {
        return {
          ...pods,
          image: images[idx],
        };
      })
    );
    animate(ref, true);
  }, [locale]);

  return (
    <>
      <Banner
        url={process.env.REACT_APP_CMS + image.data.attributes.url}
        title={heading}
        desc={description}
      />
      <div className="w-full mt-[60px] md:mt-[84px] flex justify-center">
        <div
          className="w-11/12 max-w-[1800px] grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[12px]"
          ref={(el) => (ref.current[0] = el)}
        >
          {data.map((card) => (
            <ReserveCard
              card={card}
              setIsModalOpen={setIsModalOpen}
              setOpenContactForm={setOpenContactForm}
              setSelectedType={setSelectedType}
              isGrid
            />
          ))}
        </div>
      </div>
      <ReservePods
        isOpen={isModalOpen}
        closeModal={() => {
          window.history.replaceState(null, null, window.location.pathname);
          setIsModalOpen(false);
        }}
      />
      <ContactModal
        openContactForm={openContactForm}
        setOpenContactForm={setOpenContactForm}
        selectedType={selectedType}
      />
    </>
  );
};

export default Reserve;
